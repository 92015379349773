/**
 * @license
 * @copyright Copyright Motili Inc., 2019 All Rights Reserved
 */

import _ from 'lodash';
import * as ApiService from '../../common/services/ApiService';
import {isMotiliEngineeringDept, isMotiliOwner} from '../../common/services/AuthService';

const baseUrl = 'businessChannels';

export const propertyFilter = {
    include: undefined,
    fields: [
        'id',
        'name',
        'description',
        'config'
    ],
};

/**
 * Process filter
 * @param {*} activeFilters
 * @param {*} activeQuery
 * @return {object} filter
 */
export async function processFilters(activeFilters, activeQuery) {
    const query = _.merge({}, activeQuery, {
        where: {
            and: [],
        },
    });

    if (!activeFilters.length) {
        return query;
    }
    const typeFilter = { or: [] };
    activeFilters.forEach((filter) => {
        switch (filter.target) {
            // Search Filters
            case 'NAME':
                query.where.and.push({ name: { ilike: `%${filter.term}%` } });
                break;
            default:
        }
    });
    query.where.and.push(typeFilter);
    return query;
}

/**
 * Get businessChannels
 * @param {object} query
 * @returns {Promise<Array>}
 */
export async function getBusinessChannels(query) {
    if (query) {
        return ApiService.get(true, `${baseUrl}`, { filter: query });
    }
    return [];
}

/**
 * Get tempaltes count
 * @param {object} query
 * @returns {Promise<number>}
 */
export async function getCountOfBusinessChannel(query) {
    if (query && query.where) {
        return ApiService.get(true, `${baseUrl}/count`, {
            where: query.where,
        }).then(result => result.count);
    }
    return 0;
}

/**
 * Create template
 * @param {object} data
 * @returns {Promise<object>}
 */
export async function create(data) {
    return ApiService.post(true, `${baseUrl}`, data);
}

/**
 * Get Template
 * @param {number|string} id
 * @param {object} filter
 * @returns {Promise<object>}
 */
export async function getBusinessChannel(id, filter) {
    return ApiService.get(true, `${baseUrl}/${id}`, { filter });
}

export async function getBusinessChannelByKey(key) {
    const filter = {
        where: {
            businessChannelKey: key
        }
    };
    const businessChannels = await ApiService.get(true, `${baseUrl}`, { filter });
    if (businessChannels && businessChannels.length === 1) {
        return businessChannels[0];
    }
    return null;
}

export function isBusinessChannelEditable() {
    return isMotiliOwner() && isMotiliEngineeringDept();
}

/**
 * Update template
 * @param {number} id
 * @param {object} data
 * @returns {Promise<object>}
 */
export async function update(id, data) {
    return ApiService.patch(true, `${baseUrl}/${id}`, data);
}

export async function updateNotification(businessChanelKey, notificationId, data) {
    return ApiService.post(true, `${baseUrl}/${businessChanelKey}/updateNotification/${notificationId}`, data);
}

export async function previewEmail(businessChanelKey, notificationId) {
    return ApiService.post(true, `${baseUrl}/${businessChanelKey}/previewEmail/${notificationId}`, undefined);
}


export async function testSendEmail(businessChanelKey, notificationId, data) {
    return ApiService.post(true, `${baseUrl}/${businessChanelKey}/testSendEmail/${notificationId}`, data);
}

export async function activateNotification(businessChanelKey, notificationId) {
    return ApiService.post(true, `${baseUrl}/${businessChanelKey}/activateNotification/${notificationId}`, undefined);
}

export async function deActivateNotification(businessChanelKey, notificationId) {
    return ApiService.post(true, `${baseUrl}/${businessChanelKey}/deActivateNotification/${notificationId}`, undefined);
}


export async function activateNotificationByGroupIds(businessChanelKey, groupIds) {
    return ApiService.post(true, `${baseUrl}/${businessChanelKey}/activateNotificationByGroupIds`, groupIds);
}

export async function deActivateNotificationByGroupIds(businessChanelKey, groupIds) {
    return ApiService.post(true, `${baseUrl}/${businessChanelKey}/deActivateNotificationByGroupIds`, groupIds);
}
