/**
 * Copyright Motili Inc., 2017 All Rights Reserved
 */

import React from 'react';
import Alert from 'common/components/Alert';

import ChangePassword from './ChangePassword';
import AuthStore from '../../stores/AuthStore';
import { updatePassword as AuthServiceUpdatePassword } from '../../services/AuthService';
import Config from '../../../Config';
import { ServiceWorkerContext } from '../../../useServiceWorker';
import { UserTokenContext } from '../../../useUserToken';
/**
 * Change Password
 *
 */

export default function ChangePasswordContainer(props) {
    const serviceWorker = React.useContext(ServiceWorkerContext);
    const userToken = React.useContext(UserTokenContext);
    return (
        <>
            <ChangePasswordContainerComponent
                {...props}
                serviceWorker={serviceWorker}
                userToken={userToken}
            />
        </>
    );
}

class ChangePasswordContainerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            confirmationPassword: '',
            passwordSuccessfullyReset: false,
            errorOnReset: false,
            loading: AuthStore.getState().loading,
            authenticated: AuthStore.getState().authenticated,
        };

        this.onChange = _onChange.bind(this);
        this.handleSubmitPassword = _handleSubmitPassword.bind(this);
        const params = new URLSearchParams(this.props.location.search);
        this.accessToken = params.get('access_token');
    }

    componentDidMount() {
        AuthStore.listen(this.onChange);
    }

    componentWillUnmount() {
        AuthStore.unlisten(this.onChange);
    }

    render() {
        return (
            <div>
                <ChangePassword
                    {...this.state}
                    handlePasswordChange={password => this.setState(password)}
                    handleSubmitPassword={e => this.handleSubmitPassword(e)}
                    handleGoToAccountLogin={() =>
                        this.props.history.push('/login')
                    }
                />
            </div>
        );
    }
}

function _onChange(state) {
    this.setState({
        loading: state.loading,
    });
}

function _handleSubmitPassword(e) {
    e.preventDefault();

    if (!this.state.password && !this.state.confirmationPassword) {
        return this.setState({ error: 'No password specified.' });
    }

    if (this.state.password !== this.state.confirmationPassword) {
        return this.setState({ error: 'Passwords do not match.' });
    }

    this.setState({ error: null });

    const token = JSON.parse(
        localStorage.getItem(`${Config.storageNameSpace}.token`)
    );

    console.log('update password');
    console.log(token);

    const resetPasswordData = {
        email: token.user.email,
        password: this.state.password,
        confirmationPassword: this.state.confirmationPassword,
        token: this.accessToken,
    };

    return AuthServiceUpdatePassword(resetPasswordData)
        .then(async response => {
            this.setState({ passwordSuccessfullyReset: true });

            return response;
        })
        .catch(errObject => {
            this.setState({ error: errObject.message });
            if (errObject.message === 'Invalid token') {
                Alert.error(
                    'There was an error processing the request, please contact support@motili.com if you need assistance logging into your account.'
                );
            }
        });
}
