/**
 * Copyright Motili Inc., 2016 All Rights Reserved
 */

/* eslint-disable class-methods-use-this */

import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthStore from './stores/AuthStore';
import { isMotiliAccount } from './services/AuthService';
import { ServiceWorkerContext } from '../useServiceWorker';
import { UserTokenContext } from '../useUserToken';
import UpdateAppModal from './components/modal/UpdateAppModal';
import TimeoutModal from './components/modal/TimeoutModal';

const AdminRouter = lazy(() => import('../admin/AdminRouter'));
const ClientRouter = lazy(() => import('../client/ClientRouter'));
const ContractorRouter = lazy(() => import('../contractor/ContractorRouter'));

export default function AuthenticatedRouter(props) {
    const serviceWorker = React.useContext(ServiceWorkerContext);
    const userToken = React.useContext(UserTokenContext);

    const { isUpdateAvailable, updateAssets } = serviceWorker;
    const { showTimeoutDialog, expiredIn, extendToken } = userToken;
    let dialog;
    if (isUpdateAvailable) {
        dialog = <UpdateAppModal handleUpdate={updateAssets} />;
    } else if (showTimeoutDialog) {
        dialog = (
            <TimeoutModal
                handleExtend={extendToken}
                handleClose={() => {}}
                expiredIn={expiredIn}
            />
        );
    }
    return (
        <>
            {dialog}
            <AuthenticatedRouterComponent {...props} />
        </>
    );
}

class AuthenticatedRouterComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = AuthStore.getState();

        this.onChange = state => this.setState(state);
    }

    componentDidMount() {
        AuthStore.listen(this.onChange);
        setTimeout(() => this.handleZenDeskChat(), 1500);
    }

    componentWillUnmount() {
        AuthStore.unlisten(this.onChange);
    }

    handleZenDeskChat = () => {
        if (window && window.$zopim.livechat && window.$zopim.livechat.window) {
            const script = document.createElement('script');
            script.async = true;
            if (isMotiliAccount()) {
                script.innerHTML = '$zopim.livechat.hideAll()';
            } else {
                script.innerHTML = '$zopim.livechat.button.show()';
            }
            document.body.appendChild(script);
        }
    };

    render() {
        if (this.state.authenticated) {
            if (this.state.isMotiliAccount) {
                return (
                    <AdminRouter
                        history={this.props.history}
                        redirect={this.props.location?.search}
                    />
                );
            }

            if (this.state.isClientAccount) {
                return (
                    <ClientRouter
                        history={this.props.history}
                        redirect={this.props.location?.search}
                    />
                );
            }

            if (this.state.isContractorAccount) {
                return (
                    <ContractorRouter
                        history={this.props.history}
                        redirect={this.props.location?.search}
                    />
                );
            }

            // TODO: This should do more than redirect as it represents a significant error
            // TODO: At the very least it should clear the appropriate cache
            return <Redirect to={{ pathname: '/login' }} />;
        }

        return (
            <Redirect
                to={{
                    pathname: '/login',
                    search: this.props?.location?.pathname
                        ? `redirectTo=${this.props?.location?.pathname}`
                        : '',
                }}
            />
        );
    }
}
