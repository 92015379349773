/**
 * @license
 * @copyright Copyright Motili Inc., 2017 All Rights Reserved
 */

import moment from 'moment-timezone';

import { get, patch as _patch, put, post, del } from './ApiService';
import * as Validator from '../utils/Validator';
import * as ApiService from './ApiService';

const baseUrl = 'contractors';

/**
 *
 * @param {number} id
 * @param {object} [query]
 */
export function findById(id, query) {
    return get(true, `${baseUrl}/${id}`, query);
}

/**
 *
 * @param {object} data
 * @param {object} data.filter
 */
export function find(data) {
    return post(true, `${baseUrl}/search`, data);
}

export function getActiveContractors(){
    return get(true,`${baseUrl}/getActiveContractors`)
}

/**
 *
 * @param {object} data
 * @param {object} data.where
 */
export function count(data) {
    return post(true, `${baseUrl}/search/count`, data);
}

export function patch(id, contractor) {
    return _patch(true, `${baseUrl}/${id}`, contractor);
}

export function getStatuses() {
    return get(true, `${baseUrl}/statuses`);
}

export function getPaymentMethods() {
    return get(true, `${baseUrl}/paymentMethods`);
}

export function getCorpTypes() {
    return get(true, `${baseUrl}/corpTypes`);
}
/**
 * Create a new note for the contractor
 * @param {*} contractorId
 * @param {*} data
 */
export function createNote(contractorId, data) {
    return post(true, `${baseUrl}/${contractorId}/notes`, data);
}

/**
 * Update a note for the contractor
 * @param {*} contractorId
 * @param {*} data
 */
export function updateNote(contractorId, noteId, data) {
    return put(true, `${baseUrl}/${contractorId}/notes/${noteId}`, data);
}

export function createRating(contractorId, data) {
    return post(true, `${baseUrl}/${contractorId}/ratings`, data);
}

export function getContractorStatuses() {
    return get(true, `${baseUrl}/statuses`);
}

export function activateTeamMember(contractorId, accountId) {
    return post(
        true,
        `${baseUrl}/${contractorId}/teamMembers/${accountId}/activate`
    );
}

export function updateRestrictTeamMember(contractorId, id, value) {
    return put(true, `${baseUrl}/${contractorId}/teamMembers/${id}`, {
        restrictToAssigned: value,
    });
}

export function create(contractor) {
    return post(true, `${baseUrl}/registerNewContractor`, contractor);
}

/**
 *
 * @param {*} contractorId
 * @param {*} accountId
 */
export function deactivateTeamMember(contractorId, accountId) {
    return post(
        true,
        `${baseUrl}/${contractorId}/teamMembers/${accountId}/deActivate`
    );
}

/**
 *
 * @param {*} contractorId
 * @param {*} data
 */
export function createTeamMember(contractorId, data) {
    return post(true, `${baseUrl}/${contractorId}/addTeamMember`, data);
}

// export function updateTeamMember(contractorId, memberId, data) {
//     return put(true, `${baseUrl}/${contractorId}/teamMembers/${memberId}`, data);
// }

export function updateTeamMember(contractorId, memberId, data) {
    return post(
        true,
        `${baseUrl}/${contractorId}/teamMembers/${memberId}/update`,
        data
    );
}

export function uploadQualification(contractorId, data) {
    return post(true, `${baseUrl}/${contractorId}/qualifications`, data);
}

export function deleteQualification(contractorId, qualificationId, fileId) {
    if (fileId) {
        return Promise.all([
            del(`MotiliFiles/${fileId}`),
            del(`${baseUrl}/${contractorId}/qualifications/${qualificationId}`),
        ]);
    }
    return del(`${baseUrl}/${contractorId}/qualifications/${qualificationId}`);
}

export function uploadFile(data) {
    return post(true, 'MotiliFiles', data);
}

export function deleteFile(fileId) {
    return del(`MotiliFiles/${fileId}`);
}

export function isValidAddress(address) {
    if (address.standardized && !address.stdAddress) {
        return false;
    }
    if (address.standardized === false) {
        if (!address.stdDeliveryLine) {
            return false;
        }
        if (!address.stdCity) {
            return false;
        }
        if (!address.stdRegion) {
            return false;
        }
        if (!address.stdPostalCode) {
            return false;
        }
        if (!address.timezone) {
            return false;
        }
        if (!Validator.isPostalCode(address.stdPostalCode)) {
            return false;
        }
    }
    return true;
}

export function addFavoriteDistributionCenter(
    contractorId,
    distributionCenterId
) {
    return post(
        true,
        `${baseUrl}/${contractorId}/favoriteDistributionCenterMappings`,
        {
            vendorDistributionCenterId: distributionCenterId,
        }
    );
}

export function removeFavoriteDistributionCenter(
    contractorId,
    favoriteDistributionCenterFk
) {
    return del(
        `${baseUrl}/${contractorId}/favoriteDistributionCenterMappings/${favoriteDistributionCenterFk}`
    );
}

export function updateStatus(data) {
    return _patch(true, `${baseUrl}/updateStatus`, data);
}

/**
 * Get account ids for contractor team member by full name
 * @param contractorId
 * @param name
 * @return {Promise<*>}
 */
export async function getTechnicianAccountsByName(contractorId, name) {
    if (!contractorId || !name) return [];
    const filter = {
        fields: ['id', 'userId'],
        include: [
            {
                relation: 'user',
                scope: {
                    fields: ['id'],
                    where: {
                        fullName: {
                            ilike: `%${name}%`,
                        },
                    },
                },
            },
        ],
    };
    try {
        const technicians = await get(
            true,
            `${baseUrl}/${contractorId}/teamMembers`,
            { filter }
        );
        if (technicians && technicians.length > 0) {
            return technicians.filter(t => t.user).map(t => t.user);
        }
    } catch (error) {
        console.error(error);
    }
    return [];
}

/**
 *
 * @param {object} query
 * @param {string} query.latitude
 * @param {string} query.longitude
 * @param {string} query.radius (optional)
 */
export function getContractorsNearLatLong(query) {
    return get(true, `${baseUrl}/near`, query);
}

export async function fetchContractorsTeamMembers(id, query) {
    return await get(true, `${baseUrl}/${id}/teamMembers`, query);
}

/**
 * get contractor dispatch rule associated with contractor
 * @param {Number} contractorId
 * @returns {Promise<Object>}
 */
export async function getContractorDispatchRule(contractorId) {
    return await get(true, `${baseUrl}/${contractorId}/contractorDispatchRule`);
}

/**
 * create a contractor dispatch rule
 * @param {Number} contractorId
 * @param {Object} rule
 * @returns {Promise<Object>}
 */
export async function createContractorDispatchRule(contractorId, rule) {
    return await post(
        true,
        `${baseUrl}/${contractorId}/contractorDispatchRule`,
        rule
    );
}

/**
 * update a contractor's dispatch rule
 * @param {Number} contractorId
 * @param {Object} update
 * @returns {Promise<Object>}
 */
export async function updateContractorDispatchRule(contractorId, update) {
    return await put(
        true,
        `${baseUrl}/${contractorId}/contractorDispatchRule`,
        update
    );
}

/**
 * get ruleTypes enum for use in form dropdowns
 */
export async function getRuleTypes() {
    return await get(true, 'ContractorDispatchRules/ruleTypes');
}

/**
 * @function getDispatchingDisabled
 * @param  {object} dispatchRule {contractor dispatching rule}
 * @return {boolean}
 */
export function calculateDispatchingDisabled(dispatchRule) {
    if (
        !dispatchRule ||
        !dispatchRule.active ||
        !dispatchRule.dispatchingDisabled
    )
        return false;

    return moment().isBetween(dispatchRule.startDate, dispatchRule.endDate);
}

export function getContractorMetaData(contractorId) {
    return get(true, `${baseUrl}/${contractorId}/metaData`);
}

export function getDirectSupplyClients(contractorId, query) {
    return get(true, `${baseUrl}/${contractorId}/clients`, query);
}

/**
 * Get preferred contact number
 * @param {{type: string, phoneNumber: string, ext: string, preferred: boolean}[]} phoneContacts
 * @returns {string}
 */
export function getPreferredContactNumber(phoneContacts) {
    if (phoneContacts && phoneContacts.length > 0) {
        const preferred = phoneContacts.find(
            contact => contact.preferred === true && contact.phoneNumber
        );
        if (preferred) {
            return preferred.phoneNumber;
        }
    }
    return 'N/A';
}

function _validateAdvancedAddress(address) {
    return ApiService.post(
        true,
        'Utilities/addresses/validateAdvancedAddress',
        {
            address,
        }
    ).then(response => response);
}

export function validateAdvanceAddress(addressList) {
    const promiseAll = addressList.map((search, index) => {
        const address = search.address;
        if (search.skip || address.standardized)
            return new Promise(resolve => resolve({}));
        return new Promise((resolve, reject) => {
            _validateAdvancedAddress({
                city: address.stdCity,
                state: address.stdRegion,
                zip: address.stdPostalCode,
            })
                .then(validateAddress => {
                    if (validateAddress.response.reason) {
                        throw new Error(validateAddress.response.reason);
                    }
                    resolve(validateAddress);
                })
                .catch(err => {
                    console.log(err);
                    if (search.type) {
                        err.message = `[${search.type}] ${err.message}`;
                    }
                    reject(err);
                });
        });
    });

    return Promise.all(promiseAll)
        .then(response => response) // Promise.all cannot be resolved, as one of the promises passed got rejected.
        .catch(error => {
            throw error;
        }); // Promise.all throws an error.
}

export function setAddressLatLong(address, _validateAddress, index) {
    if (_validateAddress.length >= index) {
        if (
            _validateAddress[index - 1].response &&
            _validateAddress[index - 1].response.zipcodes &&
            _validateAddress[index - 1].response.zipcodes.length > 0
        ) {
            address.latitude =
                _validateAddress[index - 1].response.zipcodes[0].latitude;
            address.longitude =
                _validateAddress[index - 1].response.zipcodes[0].longitude;
        }
    }
}

export function getpreferredClients(contractorId) {
    return get(true, `${baseUrl}/${contractorId}/preferredClients`);
}

export function addPreferredClient(clientId, preferredContractorId) {
    return post(
        true,
        `${baseUrl}/${preferredContractorId}/addPreferredClient`,
        {
            clientId,
        }
    );
}

export function removePreferredClient(clientId, preferredContractorId) {
    return del(
        `${baseUrl}/${preferredContractorId}/removePreferredClient/${clientId}`
    );
}

export function getActiveContractorMSA() {
    return get(true, `${baseUrl}/getActiveMSA`);
}
