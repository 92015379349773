/**
 * Copyright Motili Inc., 2016 All Rights Reserved
 */

import { hot } from 'react-hot-loader/root';
import React, { Suspense } from 'react';
import Alert from 'common/components/Alert';
import { ThemeProvider, theme } from 'motili-ui';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider as ReduxProvider } from 'react-redux';
import { Helmet } from 'react-helmet';

import history from './history';

import { Router, Switch, Route } from 'react-router-dom';
import reduxStore from './common/stores/redux/store';
import Login from './common/views/login/LoginContainer';
import ChangePassword from './common/views/change-password/ChangePasswordContainer';
import ResetPassword from './common/views/reset-password/ResetPasswordContainer';
import WorkRequestForm from './common/views/forms/WorkRequestFormContainer';
import MobileRouter from './common/MobileRouter';
import AuthenticatedRouter from './common/AuthenticatedRouter';
import LoadingOverlay from './common/components/LoadingOverlay';
import './style/sass/index.scss';

function App() {
    let persistor = persistStore(reduxStore);

    return (
        <>
            <Alert />
            <Suspense fallback={<LoadingOverlay />}>
                <Helmet>
                    <title>Motili</title>
                </Helmet>
                <ReduxProvider store={reduxStore}>
                    <PersistGate loading={null} persistor={persistor}>
                        <ThemeProvider theme={theme}>
                            <Router history={history}>
                                <Switch>
                                    {/* Register public routes */}
                                    <Route path='/login' component={Login} />
                                    <Route
                                        path='/updatePassword'
                                        component={ChangePassword}
                                    />
                                    <Route
                                        path='/resetpassword'
                                        component={ResetPassword}
                                    />
                                    <Route
                                        path='/request-service/:clientId/:uuid'
                                        component={WorkRequestForm}
                                    />
                                    {/* All nested routes are maintained in AuthenticatedRouter
                            (or child components as appropriate */}
                                    <Route path='/m' component={MobileRouter} />
                                    <Route
                                        path='/'
                                        component={AuthenticatedRouter}
                                    />
                                </Switch>
                            </Router>
                        </ThemeProvider>
                    </PersistGate>
                </ReduxProvider>
            </Suspense>
        </>
    );
}

export default hot(App);
