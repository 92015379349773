/**
 * @license
 * @copyright Copyright Motili Inc., 2017 All Rights Reserved
 */

/* eslint-disable no-console */

import Raven from 'raven-js';

import Config from '../../Config';

export function log(...args) {
    return info(...args);
}

export function info(...args) {
    if (Config.environment === 'local' || Config.environment === 'dev') {
        console.log(...args);
    }
}

export function warn(...args) {
    console.warn(...args);
}

export function error(...args) {
    console.error(...args);
    Raven.captureException(JSON.stringify(args, null, 2));
}
