/**
 * @license
 * @copyright Copyright Motili Inc., 2021 All Rights Reserved
 */

import React from 'react';
import AlertLib from 'react-s-alert';
import history from '../../history';

const Alert = props => {
    React.useEffect(() => {
        const unregisterCallback = history.listen(() => {
            // Remove Dialog if page change
            if (Alert.removeId) {
                AlertLib.close(Alert.removeId);
                Alert.removeId = undefined;
            }
        });
        return () => {
            unregisterCallback();
        };
    }, [Alert.removeId]);

    /**
     * Global config for alert
     */
    return <AlertLib effect={'stackslide'} position={'top'} />;
};

/**
 *
 * @param {string} msg
 * @param {object} [options]
 * @param {string} [options.position]
 * @param {boolean} [options.html]
 * @param {(string|number)} [options.timeout] - Deprecated: timeout was considered from type of message
 */
Alert.info = (msg, options, timeout = 6000) => {
    AlertLib.closeAll();
    AlertLib.info(msg, {
        ...options,
        timeout,
    });
};

/**
 *
 * @param {string} msg
 * @param {object} [options]
 * @param {string} [options.position]
 * @param {boolean} [options.html]
 * @param {(string|number)} [options.timeout] - Deprecated: timeout was considered from type of message
 */
Alert.error = (msg, options) => {
    AlertLib.closeAll();
    Alert.removeId = AlertLib.error(msg, {
        ...options,
        timeout: 'none',
    });
};

/**
 *
 * @param {string} msg
 * @param {object} [options]
 * @param {string} [options.position]
 * @param {boolean} [options.html]
 * @param {(string|number)} [options.timeout] - Deprecated: timeout was considered from type of message
 */
Alert.warning = (msg, options) => {
    AlertLib.closeAll();
    AlertLib.warning(msg, {
        ...options,
        timeout: 6000,
    });
};

/**
 *
 * @param {string} msg
 * @param {object} [options]
 * @param {string} [options.position]
 * @param {boolean} [options.html]
 * @param {(string|number)} [options.timeout] - Deprecated: timeout was considered from type of message
 */
Alert.success = (msg, options) => {
    AlertLib.closeAll();
    AlertLib.success(msg, {
        ...options,
        timeout: 2500,
    });
};

/**
 * Close dialog
 */
Alert.close = () => {
    AlertLib.closeAll();
};

Alert.removeId = undefined;

export default Alert;
