/**
 * Copyright Motili Inc., 2019 All Rights Reserved
 */

/* global document */
/* eslint-disable global-require */
import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import Raven from 'raven-js';

import Config from './Config';
import alt from './alt';
import * as AuthService from './common/services/AuthService';

import App from './App';

import history from './history';
import { ServiceWorkerProvider } from './useServiceWorker';
import './serviceWorkerErrorHandler';
import { UserTokenProvider } from './useUserToken';

(function initializeReactGA() {
    ReactGA.initialize(Config.googleAnalyticsID);
    ReactGA.pageview('/homepage');
})();
history.listen(location => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
});

Raven.config(Config.sentry.dsn, {
    release: Config.release,
    environment: Config.environment,
    shouldSendCallback: function(data) {
        if (
            Config.environment === 'local' ||
            Config.environment === 'dev' ||
            Config.environment === 'dev2'
        )
            return false;
        return true;
    },
}).install();

if (AuthService.isLoggedIn()) {
    Raven.setUserContext({
        id: AuthService.getAccount().id,
        email: AuthService.getAccount().email,
    });

    if (AuthService.isMotiliAccount()) {
        Raven.setExtraContext({
            userContext: 'ops',
        });
    }

    if (AuthService.isClientAccount()) {
        Raven.setExtraContext({
            userContext: 'cli',
        });
    }

    if (AuthService.isContractorAccount()) {
        Raven.setExtraContext({
            userContext: 'con',
        });
    }

    alt.bootstrap(
        JSON.stringify({
            AuthStore: {
                authenticated: true,
                initialized: true,
                isMotiliAccount: AuthService.isMotiliAccount(),
                isClientAccount: AuthService.isClientAccount(),
                isContractorAccount: AuthService.isContractorAccount(),
                userName: AuthService.getAccountUserName(),
                fullName: AuthService.getAccountFullName(),
                token: AuthService.getToken(),
                clientId: AuthService.getClientId(),
                contractorId: AuthService.getContractorId(),
                client: AuthService.getClient(),
                contractor: AuthService.getContractor(),
                latestAcknowledgment: AuthService.latestAcknowledgment(),
            },
        })
    );
}

ReactDOM.render(
    <ServiceWorkerProvider>
        <UserTokenProvider>
            <App />
        </UserTokenProvider>
    </ServiceWorkerProvider>,
    document.getElementById('app')
);
