/**
 * @license
 * @copyright Copyright Motili Inc., 2021 All Rights Reserved
 */

import { createSlice } from '@reduxjs/toolkit';
import findIndex from 'lodash/findIndex';

const recentlyViewedSlice = createSlice({
    name: 'recentlyViewed',
    initialState: {
        recentlyViewedDocuments: [],
    },
    reducers: {
        addDocument: (state, action) => {
            const document = action.payload.document;
            let formattedDocument = {
                id: document.id,
                number: document.number,
                statusId: document.statusId,
                documentTypeId: document.documentTypeId,
                clientName: document.client ? document.client.name : 'N/A',
            };
            switch (document.documentTypeId) {
                case 'WORK_ORDER':
                    formattedDocument.documentSubTypeId =
                        document.workOrderTypeId;
                    break;
                case 'QUOTE':
                    formattedDocument.documentSubTypeId = document.quoteTypeId;
                    break;
                case 'ORDER':
                    formattedDocument.documentSubTypeId = document.orderTypeId;
                    break;
                case 'DRAFT':
                    formattedDocument.documentSubTypeId = document.draftTypeId;
                    break;
                default:
                    formattedDocument.documentSubTypeId = 'UNKNOWN';
            }
            const recentlyViewedDocuments = state.recentlyViewedDocuments;
            const documentRecentView = findIndex(
                recentlyViewedDocuments,
                (existingDocument) => {
                    return (
                        existingDocument.id === formattedDocument.id &&
                        existingDocument.documentTypeId ===
                            formattedDocument.documentTypeId
                    );
                }
            );
            if (documentRecentView !== -1) {
                recentlyViewedDocuments.splice(documentRecentView, 1);
            }

            recentlyViewedDocuments.unshift(formattedDocument);
            if (recentlyViewedDocuments.length > 12) {
                recentlyViewedDocuments.pop();
            }
            state.recentlyViewedDocuments = recentlyViewedDocuments;
            // state.recentlyViewedDocuments = [];
        },
    },
});

export default recentlyViewedSlice.reducer;

export const { addDocument } = recentlyViewedSlice.actions;
