/**
 * @license
 * @copyright Copyright Motili Inc., 2021 All Rights Reserved
 */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    getBusinessChannel,
    create,
    update,
    updateNotification,
    activateNotification,
    deActivateNotification,
    activateNotificationByGroupIds,
    deActivateNotificationByGroupIds,
    getBusinessChannelByKey,
} from '../../services/AdminBusinessChannelsService'

export const fetchBusinessChannelById = createAsyncThunk(
    'businessChannel/fetchById',
    async ({id, query}) => {
        try {
            const response = await getBusinessChannel(id, query);
            return response;
        }
        catch (error) {
            throw Error (`An Unknown Error Occurred; Unable to Retrieve business channel for id ${id}`);
        }
    }
);

export const fetchTemplateBusinessChannel = createAsyncThunk(
    'businessChannel/fetchTemplateBusinessChannel',
    async () => {
        try {
            const response = await getBusinessChannelByKey('MOTILI');
            return response;
        }
        catch (error) {
            throw Error ('An Unknown Error Occurred; Unable to Retrieve template business channel (MOTILI)');
        }
    }
);

export const createBusinessChannel = createAsyncThunk(
    'businessChannel/create',
    async (data) => {
        try {
            const response = await create(data);
            return response;
        }
        catch (error) {
            if (error && error.constraint && error.constraint === 'business_channel_business_channel_key_key') {
                throw Error (`An Unknown Error Occurred; Unable to create a business channel duplicate key (${data.businessChannelKey})`);
            }
            throw Error (`An Unknown Error Occurred; Unable to create a business channel (${data.name})`);
        }

    }
);

export const updateBusinessChannel = createAsyncThunk(
    'businessChannel/update',
    async ({id, data}) => {
        try {
            const businessChannel = await update(id, data);
            const response = await getBusinessChannel(businessChannel.id, {include: ['notifications']});
            return response;
        }
        catch (error) {
            throw Error (`An Unknown Error Occurred; Unable to update a business channel (${id})`);
        }

    }
);

export const updateBusinessChannelNotification = createAsyncThunk(
    'businessChannel/updateNotification',
    async ({businessChannelId, businessChannelKey, notificationId, data}) => {
        try {
            await updateNotification(businessChannelKey, notificationId, data);
            const response = await getBusinessChannel(businessChannelId, {include: ['notifications']});
            return response;
        }
        catch (error) {
            throw Error (`An Unknown Error Occurred; Unable to update a notification (${notificationId})`);
        }

    }
);

export const activateBusinessChannelNotification = createAsyncThunk(
    'businessChannel/activateNotification',
    async ({businessChannelId, businessChannelKey, notificationId}) => {
        try {
            await activateNotification(businessChannelKey, notificationId);
            const response = await getBusinessChannel(businessChannelId, {include: ['notifications']});
            return response;
        }
        catch (error) {
            throw Error (`An Unknown Error Occurred; Unable to activate a notification (${notificationId})`);
        }

    }
);

export const deActivateBusinessChannelNotification = createAsyncThunk(
    'businessChannel/deActivateNotification',
    async ({businessChannelId, businessChannelKey, notificationId}) => {
        try {
            await deActivateNotification(businessChannelKey, notificationId);
            const response = await getBusinessChannel(businessChannelId, {include: ['notifications']});
            return response;
        }
        catch (error) {
            throw Error (`An Unknown Error Occurred; Unable to deactivate a notification (${notificationId})`);
        }
    }
);

export const activateAllBusinessChannelNotification = createAsyncThunk(
    'businessChannel/activateAllNotification',
    async ({businessChannelId, businessChannelKey, categoryGroupIds}) => {
        try {
            await activateNotificationByGroupIds(businessChannelKey, categoryGroupIds);
            return await getBusinessChannel(businessChannelId, {include: ['notifications']});
        }
        catch (error) {
            throw Error ('An Unknown Error Occurred; Unable to activate group of notification');
        }

    }
);

export const deActivateAllBusinessChannelNotification = createAsyncThunk(
    'businessChannel/deActivateAllNotification',
    async ({businessChannelId, businessChannelKey, categoryGroupIds}) => {
        try {
            await deActivateNotificationByGroupIds(businessChannelKey, categoryGroupIds);
            return await getBusinessChannel(businessChannelId, {include: ['notifications']});
        }
        catch (error) {
            throw Error ('An Unknown Error Occurred; Unable to deactivate group of notification');
        }

    }
);

const businessChannelSlice = createSlice({
    name: 'businessChannelSlice',
    initialState: {
        loading: false,
        businessChannel: null,
        templateBusinessChannel: null,
        successMessage: undefined,
        error: undefined,
    },
    reducers: {
        clearMessage: (state, action) => {
            state.successMessage = undefined;
            state.error = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBusinessChannelById.pending, (state, action) => {
            state.loading = true;
            state.error = undefined;
            state.successMessage = undefined;
        });
        builder.addCase(fetchBusinessChannelById.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.successMessage = undefined;
        });
        builder.addCase(fetchBusinessChannelById.fulfilled, (state, action) => {
            state.businessChannel = action.payload;
            state.loading = false;
            state.error = undefined;
            state.successMessage = undefined;
        });

        builder.addCase(fetchTemplateBusinessChannel.pending, (state, action) => {
            state.loading = true;
            state.error = undefined;
            state.successMessage = undefined;
        });
        builder.addCase(fetchTemplateBusinessChannel.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.successMessage = undefined;

        });
        builder.addCase(fetchTemplateBusinessChannel.fulfilled, (state, action) => {
            state.templateBusinessChannel = action.payload;
            state.loading = false;
            state.error = undefined;
            state.successMessage = undefined;
        });

        builder.addCase(createBusinessChannel.pending, (state, action) => {
            state.loading = true;
            state.error = undefined;
            state.successMessage = undefined;
        });
        builder.addCase(createBusinessChannel.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.successMessage = undefined;
        });
        builder.addCase(createBusinessChannel.fulfilled, (state, action) => {
            state.businessChannel = action.payload;
            state.loading = false;
            state.error = undefined;
            state.successMessage = 'Successfully created business channel';
        });

        builder.addCase(updateBusinessChannel.pending, (state, action) => {
            state.loading = true;
            state.error = undefined;
            state.successMessage = undefined;
        });
        builder.addCase(updateBusinessChannel.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.successMessage = undefined;
        });
        builder.addCase(updateBusinessChannel.fulfilled, (state, action) => {
            state.businessChannel = action.payload;
            state.loading = false;
            state.error = undefined;
            state.successMessage = 'Successfully update business channel';
        });


        builder.addCase(updateBusinessChannelNotification.pending, (state, action) => {
            //state.loading = true;
            state.error = undefined;
            state.successMessage = undefined;
        });
        builder.addCase(updateBusinessChannelNotification.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.successMessage = undefined;
        });
        builder.addCase(updateBusinessChannelNotification.fulfilled, (state, action) => {
            state.businessChannel = action.payload;
            state.loading = false;
            state.error = undefined;
            state.successMessage = 'Successfully update notification';
        });


        builder.addCase(activateBusinessChannelNotification.pending, (state, action) => {
            //state.loading = true;
            state.error = undefined;
            state.successMessage = undefined;
        });
        builder.addCase(activateBusinessChannelNotification.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.successMessage = undefined;
        });
        builder.addCase(activateBusinessChannelNotification.fulfilled, (state, action) => {
            state.businessChannel = action.payload;
            state.loading = false;
            state.error = undefined;
            state.successMessage = 'Successfully activate notification';
        });


        builder.addCase(deActivateBusinessChannelNotification.pending, (state, action) => {
            //state.loading = true;
            state.error = undefined;
            state.successMessage = undefined;
        });
        builder.addCase(deActivateBusinessChannelNotification.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.successMessage = undefined;
        });
        builder.addCase(deActivateBusinessChannelNotification.fulfilled, (state, action) => {
            state.businessChannel = action.payload;
            state.loading = false;
            state.error = undefined;
            state.successMessage = 'Successfully deactivate notification';
        });

        builder.addCase(activateAllBusinessChannelNotification.pending, (state, action) => {
            //state.loading = true;
            state.error = undefined;
            state.successMessage = undefined;
        });
        builder.addCase(activateAllBusinessChannelNotification.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.successMessage = undefined;
        });
        builder.addCase(activateAllBusinessChannelNotification.fulfilled, (state, action) => {
            state.businessChannel = action.payload;
            state.loading = false;
            state.error = undefined;
            state.successMessage = 'Successfully activate notification';
        });


        builder.addCase(deActivateAllBusinessChannelNotification.pending, (state, action) => {
            //state.loading = true;
            state.error = undefined;
            state.successMessage = undefined;
        });
        builder.addCase(deActivateAllBusinessChannelNotification.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.successMessage = undefined;
        });
        builder.addCase(deActivateAllBusinessChannelNotification.fulfilled, (state, action) => {
            state.businessChannel = action.payload;
            state.loading = false;
            state.error = undefined;
            state.successMessage = 'Successfully deactivate notification';
        });

    }
});

export default businessChannelSlice.reducer;

export const {clearMessage} = businessChannelSlice.actions;



