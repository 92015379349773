/**
 * @license
 * @copyright Copyright Motili Inc., 2021 All Rights Reserved
 */

import MetaService from '../../common/services/MetaService';
import { getCompany } from './ClientCompanyService';
import { getAccount } from '../../common/services/AuthService';

const init = async () => {
    const requests = [
        MetaService.timezones(),
        MetaService.country(),
        MetaService.states(),
        MetaService.usStates(),
        MetaService.account.preferenceTypes(),
        MetaService.account.roles(),
        MetaService.account.departmentTypes(),
        MetaService.account.types(),
        MetaService.account.roleTypes(),
        MetaService.asset.assetStatuses(),
        MetaService.asset.photoTypes(),
        getCompany(getAccount().clientTeam.clientId),
        MetaService.client.statuses(),
        MetaService.client.settings(),
        MetaService.client.freightTypes(),
        MetaService.client.orderIssues(),
        MetaService.document.types(),
        MetaService.document.tradeTypes(),
        MetaService.document.photoTypes(),
        MetaService.document.priorityTypes(),
        MetaService.document.contactTypes(),
        MetaService.document.fulfillmentTypes(),
        MetaService.document.workOrder.statuses(),
        MetaService.document.workOrder.types(),
        MetaService.document.quote.statuses(),
        MetaService.document.quote.types(),
        MetaService.document.quote.reasons(),
        MetaService.document.order.statuses(),
        MetaService.document.order.types(),
        MetaService.document.order.fulfillmentMethods(),
        MetaService.document.order.contactTypes(),
        MetaService.document.order.notificationTypes(),
        MetaService.document.order.logisticsTypes(),
        MetaService.document.order.returnStatuses(),
        MetaService.document.order.returnReasons(),
        MetaService.document.order.returnCancelReasons(),
        MetaService.product.categories(),
        MetaService.product.attributes(),
    ];

    const [
        timezones,
        country,
        states,
        usStates,
        accountPreferenceTypes,
        accountRoles,
        accountDepartmentTypes,
        accountTypes,
        accountRolesTypes,
        assetStatues,
        assetPhotoTypes,
        client,
        clientStatuses,
        clientSettings,
        clientFreightTypes,
        clientOrderIssues,
        documentTypes,
        documentTradeTypes,
        documentPhotoTypes,
        documentPriorityTypes,
        documentContactTypes,
        documentfulfillmentTypes,
        workOrderStatuses,
        workOrderTypes,
        quoteStatuses,
        quoteTypes,
        quoteReasons,
        orderStatuses,
        orderTypes,
        orderFulfillmentMethods,
        orderContactTypes,
        orderNotificationTypes,
        orderLogisticsTypes,
        orderReturnStatuses,
        orderReturnReasons,
        orderReturnCancelReasons,
        productCategories,
        productAttributes,
    ] = await Promise.all(requests);

    return {
        timezones,
        country,
        states,
        usStates,
        financial: {
            terms: [],
            statuses: [],
        },
        account: {
            opsAccounts: [],
            preferenceTypes: accountPreferenceTypes,
            roles: accountRoles,
            departmentTypes: accountDepartmentTypes,
            types: accountTypes,
            roleTypes: accountRolesTypes,
        },
        asset: {
            assetStatuses: assetStatues,
            photoTypes: assetPhotoTypes,
            retirementReasons: [],
        },
        client: {
            clients: [client],
            statuses: clientStatuses,
            settings: clientSettings,
            freightTypes: clientFreightTypes,
            integrationTypes: [],
        },
        contractor: {
            statuses: [],
            qualificationTypes: [],
            member: {
                notifications: [],
            },
            serviceTypes: [],
            certifications: [],
            credentials: [],
            // clients: [],
            blocklistReasons: [],
        },
        document: {
            types: documentTypes,
            tradeTypes: documentTradeTypes,
            photoTypes: documentPhotoTypes,
            priorityTypes: documentPriorityTypes,
            contactTypes: documentContactTypes,
            fulfillmentTypes: documentfulfillmentTypes,
            attachmentTypes: [],
            workOrder: {
                statuses: workOrderStatuses,
                types: workOrderTypes,
            },
            quote: {
                statuses: quoteStatuses,
                types: quoteTypes,
                reasons: quoteReasons,
            },
            order: {
                statuses: orderStatuses,
                types: orderTypes,
                fulfillmentMethods: orderFulfillmentMethods,
                contactTypes: orderContactTypes,
                notificationTypes: orderNotificationTypes,
                logisticsTypes: orderLogisticsTypes,
                returnStatuses: orderReturnStatuses,
                returnReasons: orderReturnReasons,
                returnCancelReasons: orderReturnCancelReasons,
                issues: clientOrderIssues,
            },
            draft: {
                statuses: [],
            },
        },
        product: {
            categories: productCategories,
            attributes: productAttributes,
            pricingTypes: [],
            unitOfMeasures: [],
        },
        project: {
            contactTypes: [],
            statuses: [],
            types: [],
            scopeTypes: [],
        },
        property: {
            types: [],
            typesNew: [],
            statuses: [],
        },
        template: {
            types: [],
        },
        ticket: {
            providers: [],
        },
        vendor: {
            allVendor: [],
            vendors: [],
            manufacturers: [],
            statuses: [],
            integrationTypes: [],
            distributionCenter: {
                configurations: [],
            },
        },
    };
};

export default { init };
