/**
 * Copyright Motili Inc., 2016 All Rights Reserved
 */
/* eslint-disable */
/* global localStorage */

import * as ApiService from '../services/ApiService';

export function ping() {
    return new Promise((resolve, reject) => {
        ApiService.post(false, 'ping')
            .then(response => resolve(response))
            .catch(error => reject(error));
    });
}
