/**
 * @license
 * @copyright Copyright Motili Inc., 2017 All Rights Reserved
 */

import * as ApiService from '../../../common/services/ApiService';

const baseUrl = 'documents';

/**
 * getDocumentSignedUrl
 * @param {*} Key - document key
 */
export function getDocumentSignedUrl(Key) {
    return ApiService.post(true, `${baseUrl}/getReportSignedUrl`, { Key });
}
