/**
 * @license
 * @copyright Copyright Motili Inc., 2019 All Rights Reserved
 */

import _ from 'lodash';
import uuidv4 from 'uuid/v4';
import moment from 'moment-timezone';
import * as DocumentService from '../components/document/DocumentService';

/**
 * Determines if the current browser supports HTML5 local storage, it is required
 * @returns {boolean}
 */
export function supportsLocalStorage() {
    // eslint-disable-line import/prefer-default-export
    return typeof Storage !== 'undefined';
}

/**
 * promiseSequence - Accepts an array of promise factory functions, executes in sequence, and returns a promise that resolves to an array of results
 * NOTE the signature is similar to Promise.all but different in that the argument array is of promise factory functions, i.e. functions that when called return a promise. Not a promise object itself.
 * @param {Function[]} promiseFactories
 * @returns {Promise<any[]>}
 */
export function promiseSequence(promiseFactories) {
    const results = arguments[1] || []; // eslint-disable-line prefer-rest-params
    if (promiseFactories.length > 0) {
        return promiseFactories[0]()
            .then(res => {
                const newArr = [].concat(results);
                newArr.push(res);
                return promiseSequence(
                    promiseFactories.splice(1, promiseFactories.length),
                    newArr
                );
            })
            .catch(err => Promise.reject(err));
    }
    return Promise.resolve(results);
}

/**
 * sortAndFilterClientRoles - return sorted filtered client roles.
 * if optional account role is passed only roles of equal or lesser privilege are returned
 * @param {object[]} roles
 * @param {string} [accountRole]
 */
export function sortAndFilterClientRoles(roles, accountRole) {
    const clientRE = /^client_/;
    return roles
        .filter(r => clientRE.test(r.id))
        .filter(r => r.id !== 'client_utility')
        .filter(r => lessPrivilegedThan(r, accountRole))
        .sort(_sortRoles);
}

export function sortAndFilterOpsRoles(roles, accountRole) {
    const opsRE = /^motili_/;
    return roles
        .filter(r => opsRE.test(r.id))
        .filter(r => lessPrivilegedThan(r, accountRole))
        .sort(_sortRoles);
}

export function sortAndFilterContractorRoles(roles, accountRole) {
    const contractorRE = /^contractor_/;
    return roles
        .filter(r => contractorRE.test(r.id))
        .filter(r => lessPrivilegedThan(r, accountRole))
        .sort(_sortRoles);
}

export function sortClientRoles(roles, accountRole) {
    const clientRE = /^client_/;
    return roles
        .filter(r => clientRE.test(r.id))
        .filter(r => r.id !== 'client_utility')
        .sort(_sortRoles);
}

export function sortOpsRoles(roles, accountRole) {
    const opsRE = /^motili_/;
    return roles.filter(r => opsRE.test(r.id)).sort(_sortRoles);
}

export function sortContractorRoles(roles, accountRole) {
    const contractorRE = /^contractor_/;
    return roles.filter(r => contractorRE.test(r.id)).sort(_sortRoles);
}

export const wholeNumberFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
});

export const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
});

export const financialFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
    minimumFractionDigits: 2,
});

export const momentDateWithTime = 'MM/DD/YYYY h:mm A';
export const momentDateWithTimeAndTimezone = 'MM/DD/YYYY h:mm A z';

export function formatDateTime(date, utc = true) {
    if (!date) return '';
    return (utc ? moment(date).utc() : moment(date)).format(momentDateWithTime);
}

export function formatDateTimeWithTimeZone(date, timezone) {
    return moment(date)
        .tz(timezone || 'UTC')
        .format(momentDateWithTimeAndTimezone);
}

export function formatDateTimeAvailableTimes(availableTimes, timezone) {
    return availableTimes.map(t => `${moment(t.startDateTime).tz(timezone || 'UTC').format('MM/DD/YYYY, h:mmA')} - ${
        moment(t.endDateTime ? t.endDateTime : moment(t.startDateTime).add(4, 'h')).tz(timezone || 'UTC').format('h:mmA zz')}`).join('\n');
}

export const momentDate = 'MM/DD/YYYY';

export function formatDateWithoutTimeZone(date) {
    const dateRemovedTime =
        typeof date === 'string' && date.length ? date.split('T')[0] : date;
    return moment(dateRemovedTime).format(momentDate);
}

export function formatDate(date, utc = true) {
    if (!date) return '';
    return (utc ? moment(date).utc() : moment(date)).format(momentDate);
}

export function formatDateRange(begin, end, dateFormat = 'MMMM D', utc = true) {
    return utc
        ? `${moment(begin)
              .utc()
              .format(dateFormat)} - ${moment(end)
              .utc()
              .format(dateFormat)}`
        : `${moment(begin).format(dateFormat)} - ${moment(end).format(
              dateFormat
          )}`;
}

export function deduceAge(creationDate) {
    if (!creationDate) return '';
    let age = moment().diff(moment(creationDate), 'years');
    if (age === 1) {
        age = `${age} year`;
    } else if (age < 1) {
        age = '< 1 year';
    } else {
        age = `${age} years`;
    }
    return age;
}

export function accountingFormat(number) {
    const _num = parseFloat(number);
    if (!_.isNaN(_num)) {
        return _num < 0
            ? `$ (${numberFormatter.format(Math.abs(_num))})`
            : `$ ${numberFormatter.format(_num)}`;
    }
    return 'N/A';
}

/**
 * tests if role is less privileged than accountRole, returns a boolean
 * @param {Object} role
 * @param {String} role.id
 * @param {String} accountRole
 * @returns {Boolean}
 */
export function lessPrivilegedThan(role, accountRole) {
    if (!accountRole) {
        return true;
    }
    const motiliRE = /^motili_/;
    const ownerRE = /_owner$/;
    const adminRE = /_admin$/;
    const managerRE = /_manager$/;
    const userRE = /_user$/;
    if (motiliRE.test(accountRole) && !motiliRE.test(role.name || role.id)) {
        return true;
    }
    if (userRE.test(accountRole)) {
        return false;
    }
    if (managerRE.test(accountRole)) {
        if (userRE.test(role.name || role.id)) {
            return true;
        }
        return false;
    }
    if (adminRE.test(accountRole)) {
        if (
            managerRE.test(role.name || role.id) ||
            userRE.test(role.name || role.id)
        ) {
            return true;
        }
        return false;
    }
    if (ownerRE.test(accountRole)) {
        return true;
    }
    return false;
}

function _sortRoles(a, b) {
    const ownerRE = /_owner$/;
    const adminRE = /_admin$/;
    const managerRE = /_manager$/;
    const userRE = /_user$/;
    if (ownerRE.test(a.id) && !ownerRE.test(b.id)) {
        return -1;
    }
    if (!ownerRE.test(a.id) && ownerRE.test(b.id)) {
        return 1;
    }
    if (ownerRE.test(a.id) && ownerRE.test(b.id)) {
        return 0;
    }
    if (adminRE.test(a.id) && !adminRE.test(b.id)) {
        return -1;
    }
    if (!adminRE.test(a.id) && adminRE.test(b.id)) {
        return 1;
    }
    if (adminRE.test(a.id) && adminRE.test(b.id)) {
        return 0;
    }
    if (managerRE.test(a.id) && !managerRE.test(b.id)) {
        return -1;
    }
    if (!managerRE.test(a.id) && managerRE.test(b.id)) {
        return 1;
    }
    if (managerRE.test(a.id) && managerRE.test(b.id)) {
        return 0;
    }
    if (userRE.test(a.id) && !userRE.test(b.id)) {
        return -1;
    }
    if (!userRE.test(a.id) && userRE.test(b.id)) {
        return 1;
    }
    if (userRE.test(a.id) && userRE.test(b.id)) {
        return 0;
    }
    return 0;
}

export function generateRandomUUID() {
    return uuidv4();
}

/**
 * deleteEmptyKeys - returns a new object with non-undefined, null, NaN, and empty string (after calling .trim()) key-value pairs excluded
 * @param {Object} obj
 * @returns {Object}
 */
export function deleteEmptyKeys(obj) {
    const _obj = {};
    for (const key in obj) {
        // eslint-disable-line no-restricted-syntax
        if ({}.hasOwnProperty.call(obj, key)) {
            if (
                !_.isNil(obj[key]) &&
                !_.isNaN(obj[key]) &&
                obj[key] !== '' &&
                !(obj[key].trim && obj[key].trim() === '')
            ) {
                _obj[key] = obj[key];
            }
        }
    }
    return _obj;
}

/**
 * concatStringWithElipses - returns a new string of a specified length, concatenated with elipses
 * @param {String} string
 * @param {Number} length
 * @returns {String}
 */
export function concatStringWithElipses(string, length) {
    return string && string.length > length
        ? string.substring(0, length).concat('', '...')
        : string;
}

export function trimWhiteSpace(string) {
    return string.toString().replace(/^\s+|\s+$/g, '');
}

export function parseMessageDates(message, dates, propertyTimeZone) {
    let _message;
    Object.keys(dates).forEach(dateKey => {
        if (propertyTimeZone) {
            _message = message.replace(
                `{${dateKey}}`,
                moment(dates[dateKey])
                    .tz(propertyTimeZone, true)
                    .format(momentDateWithTimeAndTimezone)
            );
        } else {
            _message = message.replace(
                `{${dateKey}}`,
                moment(dates[dateKey]).format(momentDateWithTime)
            );
        }
    });
    return _message;
}

export function getMinDateTimeForScheduleJob() {
    const now = moment();
    if (now.minute() > 0) {
        return now
            .add(4, 'hours')
            .minute(0)
            .second(0)
            .millisecond(0);
    }
    return now
        .add(3, 'hours')
        .minute(0)
        .second(0)
        .millisecond(0);
}

export function mappedDateParse(date, propertyTimeZone) {
    if (date === null || date._isAMomentObject) {
        return date;
    }
    const timeZones = {
        akdt: '-0800',
        AKDT: '-0800',
        akst: '-0900',
        hst: '-1000',
        hdt: '-900',
    };

    /*eslint-disable */
    for (const zone in timeZones) {
        const regex = new RegExp(zone, 'i');
        if (date.toLowerCase().search(regex) !== -1) {
            const newDateString = date.replace(regex, timeZones[zone]);
            const time = propertyTimeZone
                ? moment.utc(newDateString).tz(propertyTimeZone, true)
                : moment.utc(newDateString);
            return time;
        }
    }

    return moment(date);
}

/**
 * Get the nearest half-hour time interval
 *
 * @param {string} propertyTimeZone
 * @returns {moment.Moment}
 */
export function getTimeAtNextHalfHour(propertyTimeZone) {
    const now = propertyTimeZone ? moment().tz(propertyTimeZone) : moment();
    const remainder = 30 - (now.minute() % 30);

    return moment(now).add(remainder, 'minutes');
}

export function responseError(err) {
    if (_.isString(err)) {
        return err;
    }
    return `${err.statusCode} | ${err.message}`;
}

export function unathenticatedErrorObject() {
    // TODO: change message to 'Action not authorized, please contact Motili Support for assistance'
    const errObject = new Error(
        'Unable to resolve authenticated request, no token or token has expired'
    );
    errObject.statusCode = 401;
    return responseError(errObject);
}

export function navigateUp(url, n = 1) {
    return _.chain(url)
        .split('/')
        .dropRight(n)
        .join('/')
        .value();
}

/**
 * @param  {string} phone {phone number string}
 * @return {string} {phone number string with mask}
 */
export function applyPhoneMask(phone) {
    const _phone = phone
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return `(${_phone[1]}) ${_phone[2]}-${_phone[3]}`;
}

/**
 *
 * @param {string} text the text to validate
 * @param {number} [minLength = 3] minimum length allow after trimmed
 * @returns {boolean}
 */
export function textLengthAtLeast(text = '', minLength = 3) {
    return _.trim(text).length >= minLength;
}

/**
 *
 * @param amount
 * @return {string}
 */
export function formatFinancial(amount) {
    const _num = parseFloat(amount);
    if (!_.isNaN(_num)) {
        return _num < 0
            ? `$(${financialFormatter.format(Math.abs(_num))})`
            : `$${financialFormatter.format(_num)}`;
    }
    return 'N/A';
}

/**
 *
 * @param selectedContractorId
 * @param contractors
 * @return {{state: string, message: string}|{}}
 */
export function getContractorValidation(selectedContractorId, contractors) {
    const contractor = contractors.find(c => c.id === selectedContractorId);
    if (contractor && !contractor.taxId) {
        return {
            state: 'error',
            message:
                'This contractor does not have a Tax ID in our system. Please collect and add a Tax ID to ensure we can bill correctly',
        };
    }
    return {};
}

/**
 * Remove all non-numeric characters, and return result string
 *
 * @param {string} input
 *
 * @returns {string} Sanitized output
 */
export function filterNumericValue(input) {
    return input.replace(/\D/g, '');
}

export function clean(input) {
    return input.replace(/[^\x20-\x7E]/g, '');
}

export async function getDocumentSignedUrl(evt, key = '') {
    evt.preventDefault();
    let documentUrl = key;
    const isCloudinaryLink = documentUrl?.toLowerCase()?.includes('cloudinary');
    if (!isCloudinaryLink) {
        documentUrl = await DocumentService.getDocumentSignedUrl(key);
    }

    window.open(documentUrl, '_blank');
}

export function toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

export function addMenusDivider(items) {
    if (items.length <= 1) {
        return items;
    }
    const itemsAddedDivider = [];
    for (let i = 0; i < items.length; i++) {
        itemsAddedDivider.push(items[i]);
        if (i >= 0 && i < items.length - 1) {
            itemsAddedDivider.push({ divider: true });
        }
    }
    return itemsAddedDivider;
}
