/**
 * Copyright Motili Inc., 2016 All Rights Reserved
 */

import React from 'react'; // eslint-disable-line no-unused-vars

import './style/sass/layout.scss';

/**
 * Content Wrapper
 */
export default function Wrapper(props) {
    return (
        <div className={'layout'}>
            <div className={'wrapper'}>{props.children}</div>
        </div>
    );
}
