/**
 * Copyright Motili Inc., 2016 All Rights Reserved
 */

/* eslint-disable class-methods-use-this */

import _ from 'lodash';

import alt from '../../alt';

import AuthActions from '../actions/AuthActions';
import * as AuthService from '../services/AuthService';

class AuthStore {
    constructor(actions) {
        this.state = {
            loading: false,
            busy: false,
            initialized: false,
            authenticated: false,
            isMotiliAccount: false,
            isClientAccount: false,
            clientId: null,
            client: null,
            isContractorAccount: false,
            contractorTeamMemberId: null,
            contractorId: null,
            contractor: null,
            userName: null,
            fullName: null,
            token: null,
            latestAcknowledgment: null,
        };
        this.bindActions(actions);
    }

    fetchedToken(token) {
        this.setState({
            initialized: true,
            authenticated: true,
            isMotiliAccount: AuthService.isMotiliAccount(),
            isClientAccount: AuthService.isClientAccount(),
            client: AuthService.getClient(),
            isContractorAccount: AuthService.isContractorAccount(),
            contractor: AuthService.getContractor(),
            userName: AuthService.getAccountUserName(),
            fullName: AuthService.getAccountFullName(),
            clientId: AuthService.getClientId(),
            contractorId: AuthService.getContractorId(),
            contractorTeamMemberId: AuthService.getContractorTeamMemberId(),
            token: token,
            loading: false,
            latestAcknowledgment: AuthService.latestAcknowledgment(),
        });
    }

    fetchTokenFailed(error) {
        this.setState({
            loading: false,
            initialized: false,
            authenticated: false,
            errorMessage: error,
        });
        return AuthActions.clearAllMessages.defer();
    }

    logoutSuccess(response) {
        this.state.loading = false;
        this.state.initialized = false;
        this.state.authenticated = false;
    }

    logoutFailed(error) {
        this.state.loading = false;
        this.state.initialized = false;
        this.state.authenticated = false;
        this.state.errorMessage = error;
    }

    failedForgotPassword(error) {
        this.state.loading = false;
        this.state.errorMessage = error;
        AuthActions.clearAllMessages.defer();
    }

    forgotPasswordSuccess(response) {
        this.state.loading = false;
        this.state.message =
            'Password reset instructions have been sent to your email address.';
        AuthActions.clearAllMessages.defer();
    }

    clearAllMessages() {
        this.state.message = null;
        this.clearErrors();
    }

    clearErrors() {
        this.state.errorMessage = null;
    }

    updateAccount(account) {
        const _account = _.assign({}, this.state.token.user, {
            email: account.email,
            firstName: account.firstName,
            lastName: account.lastName,
            phone: account.phone,
            timezoneId: account.timezoneId,
            preferences: account.preferences,
            notificationPreferences: account.notificationPreferences,
            contractorTeam: account.contractorTeam,
            clientTeam: account.clientTeam,
        });

        this.setState({
            initialized: true,
            authenticated: true,
            isMotiliAccount: AuthService.isMotiliAccount(),
            isClientAccount: AuthService.isClientAccount(),
            client: AuthService.getClient(),
            isContractorAccount: AuthService.isContractorAccount(),
            contractor: AuthService.getContractor(),
            userName: AuthService.getAccountUserName(),
            fullName: AuthService.getAccountFullName(),
            clientId: AuthService.getClientId(),
            contractorTeamMemberId: AuthService.getContractorTeamMemberId(),
            contractorId: AuthService.getContractorId(),
            token: _.assign({}, this.state.token, { user: _account }),
            loading: false,
        });
    }

    acceptAcknowledgement() {
        this.setState({
            latestAcknowledgment: AuthService.latestAcknowledgment(),
        });
    }

    updateContractor (contractor) {
        this.setState({
            contractor: {...this.state.contractor,...contractor}
        })
    }
}

export default alt.createStore(AuthStore, 'AuthStore', AuthActions);
