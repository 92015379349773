/**
 * @license
 * @copyright Copyright Motili Inc., 2017 All Rights Reserved
 */

import * as ApiService from './ApiService';

const baseUrl = 'accounts';

/**
 * findById
 * @param {*} id
 * @param {*} query
 */
export function findById(id, query) {
    return ApiService.get(true, `${baseUrl}/${id}`, query);
}

/**
 * finds accounts
 * @param {Object} data
 * @param {Object} data.filter
 * @returns {Promise<Object>}
 */
export function find(data) {
    return ApiService.post(true, `${baseUrl}/search`, data);
}

/**
 * counts accounts
 * @param {object} data
 * @param {object} data.where
 */
export function count(data) {
    return ApiService.post(true, `${baseUrl}/search/count`, data);
}

// TODO: NEED TO TAKE A LONG HARD LOOK AT 'patch' and 'update' behaviour

/**
 * patch
 * @param {Number} id
 * @param {Object} _update
 * @return {Promise<Object>}
 */
export function patch(id, _update) {
    return ApiService.patch(true, `${baseUrl}/${id}`, _update);
}

/**
 * update DO NOT CONFUSE WITH PATCH! HAS SPECIFIC USE CASES
 * @param id
 * @param _update
 * @returns {Promise}
 */
export function update(id, _update) {
    return ApiService.patch(true, `${baseUrl}/${id}/update`, _update);
}

// TODO: END

/**
 * register
 * @param {Object} data
 * @return {Promise<object>}
 */
export function register(data) {
    return ApiService.post(true, `${baseUrl}/register`, data);
}

/**
 * reset account password
 * @param data
 * @returns {Promise}
 */
export function resetPassword(data) {
    return ApiService.post(true, `${baseUrl}/reset`, data);
}

/**
 * Activate an account
 * @param id
 * @returns {Promise}
 */
export function activate(id) {
    return ApiService.post(true, `${baseUrl}/${id}/activate`);
}

/**
 * Deactivate an account
 * @param id
 * @returns {Promise}
 */
export function deactivate(id) {
    return ApiService.post(true, `${baseUrl}/${id}/deactivate`);
}

export function createPreference(accountId, preference) {
    return ApiService.post(
        true,
        `${baseUrl}/${accountId}/preferences`,
        preference
    );
}

export function getAcknowledgementTypes() {
    return ApiService.get(true, `${baseUrl}/acknowledgmentTypes`);
}

export function createAcknowledgement(accountId, acknowledgement) {
    return ApiService.post(
        true,
        `${baseUrl}/${accountId}/acknowledgments`,
        acknowledgement
    );
}

/**
 * Gets an account access token for display in client integration
 * @param id
 * @returns {Promise}
 */
export function getToken(id) {
    return ApiService.get(true, `${baseUrl}/${id}/application/getToken`);
}

export function getUserAndGroupMentionslist() {
    return ApiService.get(true, `${baseUrl}/get-user-and-group-mentions-list`);
}
export function addProjectRelationship(accountId, projects) {
    return ApiService.post(
        true,
        `${baseUrl}/${accountId}/projectRelationship`,
        projects
    );
}
export function removeProjectRelationship(accountId, projects) {
    return ApiService.post(
        true,
        `${baseUrl}/${accountId}/projectRelationship`,
        projects
    );
}

export function getProjectRelationship(accountId) {
    return ApiService.get(true, `${baseUrl}/${accountId}/projectRelationship`);
}

export function generateWorkOrderCsv(accountId, workOrders) {
    return ApiService.post(
        true,
        `${baseUrl}/${accountId}/generateCsv`,
        workOrders
    );
}
