import React from 'react'; // eslint-disable-line no-unused-vars

import Modal from './Modal';
import MotiliButton from '../buttons/MotiliButton';
import MotiliButtonContainer from '../buttons/MotiliButtonContainer';

export default function TimeoutModal({ handleExtend, handleClose, expiredIn }) {
    const secondsRemain = expiredIn % 60;
    const minutesExpired = (expiredIn - secondsRemain) / 60;
    const timeDisplay =
        minutesExpired > 0
            ? `${minutesExpired}:${
                  secondsRemain < 10 ? `0${secondsRemain}` : secondsRemain
              } minutes`
            : `${secondsRemain} seconds`;
    return (
        <Modal
            controlId={'session-inactivity-modal'}
            headerStyles={{ fontSize: 32 }}
            header={'Session Inactivity'}
            onCloseClick={handleClose}
            showClose={false}
            closeByDocument={false}
            footer={
                <MotiliButtonContainer>
                    <MotiliButton
                        text={`I'm Still Working`}
                        onClick={handleExtend}
                    />
                </MotiliButtonContainer>
            }
        >
            <div>
                The system has not detected any activity for the past hour and
                will automatically log you out in the{' '}
                <b>next{` ${timeDisplay}`}</b>{' '}
            </div>
        </Modal>
    );
}
