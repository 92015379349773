/**
 * Copyright Motili Inc., 2016 All Rights Reserved
 */

import alt from '../../alt';

class AuthActions {
    constructor() {
        this.generateActions(
            'fetchedToken',
            'fetchTokenFailed',
            'logoutSuccess',
            'logoutFailed',
            'clearAllMessages',
            'clearErrors',
            'failedForgotPassword',
            'forgotPasswordSuccess',
            'updateAccount',
            'acceptAcknowledgement',
            'updateContractor'
        );
    }
}

export default alt.createActions(AuthActions);
