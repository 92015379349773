/**
 * @license
 * @copyright Copyright Motili Inc., 2017 All Rights Reserved
 */

import React, { Component } from 'react';
import Button from 'react-bootstrap/lib/Button';
import PropTypes from 'prop-types';

import Config from '../../../Config';
import BusySpinner from '../busy-spinner/BusySpinner';

export default class MotiliButton extends Component {
    static propTypes = {
        onClick: PropTypes.func,
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        disabled: PropTypes.bool,
        block: PropTypes.bool,
        type: PropTypes.string,
        id: PropTypes.string,
        active: PropTypes.bool,
        size: PropTypes.oneOf(['sm', 'md', 'lg']).isRequired,
        moStyle: PropTypes.oneOf([
            'normal',
            'primary',
            'navy',
            'action',
            'blueOutline',
            'navyOutline',
        ]).isRequired,
        busy: PropTypes.bool,
    };

    static defaultProps = {
        size: 'md',
        moStyle: 'primary',
        busy: false,
    };

    render() {
        return (
            <Button
                block={this.props.block}
                bsStyle='primary'
                type={this.props.type}
                disabled={this.props.busy || this.props.disabled}
                onClick={e => {
                    e.currentTarget.blur();
                    return this.props.onClick(e);
                }}
                id={this.props.id}
                className={
                    classes[this.props.moStyle][
                        this.props.block ? 'block' : this.props.size
                    ]
                }
                active={this.props.active}
            >
                {this.props.busy ? (
                    <BusySpinner
                        busy={this.props.busy}
                        color={styles.busySpinner[this.props.moStyle].color}
                    />
                ) : (
                    <span>{this.props.text}</span>
                )}
            </Button>
        );
    }
}

const styles = {
    busySpinner: {
        normal: {
            color: Config.color.motiliLightBlue,
        },
        primary: {
            color: Config.color.white,
        },
        navy: {
            color: Config.color.white,
        },
        action: {
            color: Config.color.motiliLightBlue,
        },
        blueOutline: {
            color: Config.color.motiliLightBlue,
        },
        navyOutline: {
            color: Config.color.navy,
        },
    },
};

const classes = {
    primary: {
        sm: 'motili-btn-primary-sm',
        md: 'motili-btn-primary',
        lg: 'motili-btn-primary-lg',
        block: 'motili-btn-primary-block',
    },
    normal: {
        sm: 'motili-btn-sm',
        md: 'motili-btn',
        lg: 'motili-btn-lg',
    },
    navy: {
        sm: 'motili-btn-navy-sm',
        md: 'motili-btn-navy',
        lg: 'motili-btn-navy-lg',
    },
    action: {
        sm: 'motili-btn-action-sm',
        md: 'motili-btn-action',
        lg: 'motili-btn-action-lg',
    },
    blueOutline: {
        sm: 'motili-btn-blue-outline-sm',
        md: 'motili-btn-blue-outline',
        lg: 'motili-btn-blue-outline-lg',
        block: 'motili-btn-blue-outline-block',
    },
    navyOutline: {
        sm: 'motili-btn-navy-outline-sm',
        md: 'motili-btn-navy-outline',
        lg: 'motili-btn-navy-outline-lg',
    },
};
