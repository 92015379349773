/**
 * Copyright Motili Inc., 2016 All Rights Reserved
 */

import pjson from '../package.json';

const WP_ENVIRONMENT = process.env.REACT_APP_ENV || 'local';
const VERSION = `${pjson.version}.${pjson.sprint}.${
    pjson.build === '0' ? Date.now() : pjson.build
}`;
const RELEASE = pjson.version;
const STORAGE_NAMESPACE = 'motili';
const SENTRY = {
    dsn: 'https://6ba23886c40048f9bf2b496cec7adc99@sentry.io/282123',
};
const ROUTING = {
    opsBaseRoute: '/ops',
    cliBaseRoute: '/cli',
    conBaseRoute: '/con',
    toolsBaseRoute: '/tools',
};

const COLOR = {
    darkNavy: '#0e2248',
    navy: '#17335d',
    motiliLightBlue: '#57c1e9',
    mapMarkerBlue: '#3f82a5',
    blue: '#2196F3',
    teal: '#01BCD4',
    green: '#15b039',
    white: '#FFFFFF',
    black: '#000000',
    orange: '#fc6d17',
    red: '#e63232',
    mapMarkerYellow: '#ffee00',
    yellow: '#fcb817',
    darkGray: '#3b3b3b',
    mediumGray: '#818181',
    lightGray: '#e9e9e9',
    purple: '#673AB7',
};

const PRODUCT_SEARCH_FUSE_OPTIONS = {
    // id: 'id',
    shouldSort: true,
    tokenize: true,
    matchAllTokens: true,
    includeScore: true,
    includeMatches: true,
    threshold: 0.3,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['display', 'details'],
};
const MODAL_PRODUCT_SEARCH_FUSE_OPTIONS = PRODUCT_SEARCH_FUSE_OPTIONS;
MODAL_PRODUCT_SEARCH_FUSE_OPTIONS.keys = ['sku', 'display', 'details'];
const MANAGED_PRICING_SEARCH_FUSE_OPTIONS = {
    tokenize: true,
    matchAllTokens: true,
    shouldSort: true,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['product.sku', 'product.details', 'product.display'],
};
const MANAGED_VENDOR_SEARCH_FUSE_OPTIONS = {
    tokenize: true,
    matchAllTokens: true,
    shouldSort: true,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['vendorBranchName', 'vendorBranchNumber'],
};
const SYSTEM_ASSET_CONFIGURATION_SEARCH_FUSE_OPTIONS = {
    tokenize: true,
    matchAllTokens: true,
    shouldSort: true,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    threshold: 0.4,
    keys: ['tradeTypeId', 'display', 'attributes'],
};
const BULK_CREATION = {
    maximum: 500,
};

const STATES = {
    states: [
        'AL',
        'AK',
        'AS',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'DC',
        'FM',
        'FL',
        'GA',
        'GU',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MH',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'MP',
        'OH',
        'OK',
        'OR',
        'PW',
        'PA',
        'PR',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VI',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY',
    ],
};

const BRANCHES = [
    { display: 'Air Force' },
    { display: 'Army' },
    { display: 'Navy' },
];
const REGIONS = [
    { display: 'Region-1' },
    { display: 'Region-2' },
    { display: 'Region-3' },
    { display: 'Region-4' },
    { display: 'Region-5' },
    { display: 'Region-6' },
    { display: 'Region-7' },
    { display: 'Region-8' },
    { display: 'Region-9' },
    { display: 'Region-10' },
];
const SHOW_TERRITORY_CLIENTS = ['BALFOUR BEATTY'];

const DEFAULT_ASSET_TAGGING_FEE = {
    REPLACE: {
        adjustedPrice: 0,
        adjustedPriceIncludingTax: 0,
        price: 0,
        priceAdjustment: 0,
        priceIncludingTax: 0,
        adjustedPayout: 0,
    },
};

const INVOICE_REMIT_TO = {
    name: 'Motili, Inc.',
    address: 'PO Box 655028',
    city: 'Dallas',
    state: 'TX',
    zipCode: '75265-5028',
    country: 'United States',
    email: '',
};

const LIFTGATE_SKU = 'MHVC-RES-SHPFRT-SHPLFGT';

const config = {
    local: {
        googleAnalyticsID: 'UA-154690269-1',
        environment: WP_ENVIRONMENT,
        release: RELEASE,
        version: VERSION,
        apiBaseUrl: 'http://localhost:3000/api',
        mossApiBaseUrl: 'http://localhost:3060/api/moss/',
        motiliNotificationServiceApiBaseUrl: 'localhost:3010',
        requirementsBuilderApi: 'http://localhost:3001/rb/api',
        baseCDNURL : 'https://static.motili.com/',
        storageNameSpace: STORAGE_NAMESPACE,
        sentry: SENTRY,
        color: COLOR,
        routing: ROUTING,
        productSearchFuseOptions: PRODUCT_SEARCH_FUSE_OPTIONS,
        modalProductSearchFuseOptions: MODAL_PRODUCT_SEARCH_FUSE_OPTIONS,
        managedPricingSearchFuseOptions: MANAGED_PRICING_SEARCH_FUSE_OPTIONS,
        managedVendorSearchFuseOptions: MANAGED_VENDOR_SEARCH_FUSE_OPTIONS,
        systemAssetConfigurationSearchFuseOptions: SYSTEM_ASSET_CONFIGURATION_SEARCH_FUSE_OPTIONS,
        autoSyncFinance: true,
        bulkCreation: BULK_CREATION,
        states: STATES,
        baseQrCodeUrl: 'assets.motilidev.com/tag/',
        helpLinks: [
            {
                title: 'Help Center',
                text: 'Visit the Motili Help Center',
                href: 'https://help.motilistage.com/Content/Home.htm',
            },
            {
                title: 'Quick Start',
                text: 'Motili Quick Start Guide',
                href:
                    'https://help.motilistage.com/Content/Accept%20a%20Job/Quick%20Start.htm',
            },
            {
                title: 'Submit a Work Order',
                text: 'How to Submit a Work Order',
                href:
                    'https://help.motilistage.com/Content/Use%20the%20Contractor%20Platform/Complete%20a%20Work%20Order/Submit%20the%20Work%20Order.htm',
            },
        ],
        tokenValidation: {
            backendInterval: 5, //minute
            timeBeforeExpired: 5, // minute
        },
        territory: {
            branches: BRANCHES,
            regions: REGIONS,
        },
        showTerritoryClients: SHOW_TERRITORY_CLIENTS,
        defaultAssetTaggingFee: DEFAULT_ASSET_TAGGING_FEE,
        defaultShippingSearchWebsite:
            'https://www.parcelmonitor.com/track-united-states//',
        invoiceRemitTo: INVOICE_REMIT_TO,
        liftGateSku: LIFTGATE_SKU,
        aws: {
            s3: {
                motiliFiles: {
                    bucketName: 'dev-motili-files',
                    identityPoolId:
                        'us-west-2:32b7f8c5-cea7-4a57-8fb5-2c71aff6a6e5',
                    region: 'us-west-2',
                },
            },
        },
    },
    release: {
        googleAnalyticsID: '',
        environment: WP_ENVIRONMENT,
        release: RELEASE,
        version: VERSION,
        apiBaseUrl: 'https://api.slalom-dev.motili.link/api',
        mossApiBaseUrl: 'https://api.slalom-dev.motili.link/api/moss',
        motiliNotificationServiceApiBaseUrl: 'NO URL!',
        requirementsBuilderApi: 'https://api.slalom-dev.motili.link/rb/api',
        baseCDNURL : '',
        storageNameSpace: STORAGE_NAMESPACE,
        sentry: SENTRY,
        color: COLOR,
        routing: ROUTING,
        productSearchFuseOptions: PRODUCT_SEARCH_FUSE_OPTIONS,
        modalProductSearchFuseOptions: MODAL_PRODUCT_SEARCH_FUSE_OPTIONS,
        managedPricingSearchFuseOptions: MANAGED_PRICING_SEARCH_FUSE_OPTIONS,
        managedVendorSearchFuseOptions: MANAGED_VENDOR_SEARCH_FUSE_OPTIONS,
        systemAssetConfigurationSearchFuseOptions: SYSTEM_ASSET_CONFIGURATION_SEARCH_FUSE_OPTIONS,
        autoSyncFinance: true,
        bulkCreation: BULK_CREATION,
        states: STATES,
        baseQrCodeUrl: 'https://assets.slalom-dev.motili.link/tag/',
        helpLinks: [
            {
                title: 'Help Center',
                text: 'Visit the Motili Help Center',
                href: 'https://help.slalom-dev.motili.link',
            },
            {
                title: 'Quick Start',
                text: 'Motili Quick Start Guide',
                href:
                    '',
            },
            {
                title: 'Submit a Work Order',
                text: 'How to Submit a Work Order',
                href:
                    '',
            },
        ],
        tokenValidation: {
            backendInterval: 5, //minute
            timeBeforeExpired: 5, // minute
        },
        territory: {
            branches: BRANCHES,
            regions: REGIONS,
        },
        showTerritoryClients: SHOW_TERRITORY_CLIENTS,
        defaultAssetTaggingFee: DEFAULT_ASSET_TAGGING_FEE,
        defaultShippingSearchWebsite:
            'https://www.parcelmonitor.com/track-united-states//',
        invoiceRemitTo: INVOICE_REMIT_TO,
        liftGateSku: LIFTGATE_SKU,
        aws: {
            s3: {
                motiliFiles: {
                    bucketName: 'slalom-dev-859862577465-files',
                    identityPoolId:
                        '',
                    region: 'us-west-2',
                },
            },
        },
    },
    dev: {
        googleAnalyticsID: 'UA-154690269-1',
        environment: WP_ENVIRONMENT,
        release: RELEASE,
        version: VERSION,
        apiBaseUrl: 'https://api.slalom-dev.motili.link/api',
        mossApiBaseUrl: 'https://s2.motilidev.com/api/moss/',
        motiliNotificationServiceApiBaseUrl: 'https://ns.motilidev.com',
        requirementsBuilderApi: 'https://api.slalom-dev.motili.link/api/rb',
        baseCDNURL : 'https://static.motili.com/',
        storageNameSpace: STORAGE_NAMESPACE,
        sentry: SENTRY,
        color: COLOR,
        routing: ROUTING,
        productSearchFuseOptions: PRODUCT_SEARCH_FUSE_OPTIONS,
        modalProductSearchFuseOptions: MODAL_PRODUCT_SEARCH_FUSE_OPTIONS,
        managedPricingSearchFuseOptions: MANAGED_PRICING_SEARCH_FUSE_OPTIONS,
        managedVendorSearchFuseOptions: MANAGED_VENDOR_SEARCH_FUSE_OPTIONS,
        systemAssetConfigurationSearchFuseOptions: SYSTEM_ASSET_CONFIGURATION_SEARCH_FUSE_OPTIONS,
        autoSyncFinance: true,
        bulkCreation: BULK_CREATION,
        states: STATES,
        baseQrCodeUrl: 'assets.motilidev.com/tag/',
        helpLinks: [
            {
                title: 'Help Center',
                text: 'Visit the Motili Help Center',
                href: 'https://help.motilistage.com/Content/Home.htm',
            },
            {
                title: 'Quick Start',
                text: 'Motili Quick Start Guide',
                href:
                    'https://help.motilistage.com/Content/Accept%20a%20Job/Quick%20Start.htm',
            },
            {
                title: 'Submit a Work Order',
                text: 'How to Submit a Work Order',
                href:
                    'https://help.motilistage.com/Content/Use%20the%20Contractor%20Platform/Complete%20a%20Work%20Order/Submit%20the%20Work%20Order.htm',
            },
        ],
        tokenValidation: {
            backendInterval: 5, //minute
            timeBeforeExpired: 5, // minute
        },
        territory: {
            branches: BRANCHES,
            regions: REGIONS,
        },
        showTerritoryClients: SHOW_TERRITORY_CLIENTS,
        defaultAssetTaggingFee: DEFAULT_ASSET_TAGGING_FEE,
        defaultShippingSearchWebsite:
            'https://www.parcelmonitor.com/track-united-states//',
        invoiceRemitTo: INVOICE_REMIT_TO,
        liftGateSku: LIFTGATE_SKU,
        aws: {
            s3: {
                motiliFiles: {
                    bucketName: 'dev-motili-files',
                    identityPoolId:
                        'us-west-2:32b7f8c5-cea7-4a57-8fb5-2c71aff6a6e5',
                    region: 'us-west-2',
                },
            },
        },
    },
    dev2: {
        googleAnalyticsID: 'UA-154690269-1',
        environment: WP_ENVIRONMENT,
        release: RELEASE,
        version: VERSION,
        apiBaseUrl: 'https://api2.motilidev.com/api',
        mossApiBaseUrl: 'https://api2.motilidev.com/api/moss/',
        motiliNotificationServiceApiBaseUrl: 'https://ns.motilidev.com',
        requirementsBuilderApi: 'https://api.dev2.motilidev.com/rb/api',
        baseCDNURL : 'https://static.motili.com/',
        storageNameSpace: STORAGE_NAMESPACE,
        sentry: SENTRY,
        color: COLOR,
        routing: ROUTING,
        productSearchFuseOptions: PRODUCT_SEARCH_FUSE_OPTIONS,
        modalProductSearchFuseOptions: MODAL_PRODUCT_SEARCH_FUSE_OPTIONS,
        managedPricingSearchFuseOptions: MANAGED_PRICING_SEARCH_FUSE_OPTIONS,
        managedVendorSearchFuseOptions: MANAGED_VENDOR_SEARCH_FUSE_OPTIONS,
        systemAssetConfigurationSearchFuseOptions: SYSTEM_ASSET_CONFIGURATION_SEARCH_FUSE_OPTIONS,
        autoSyncFinance: true,
        bulkCreation: BULK_CREATION,
        states: STATES,
        baseQrCodeUrl: 'assets.motilidev.com/tag/',
        helpLinks: [
            {
                title: 'Help Center',
                text: 'Visit the Motili Help Center',
                href: 'https://help.motilistage.com/Content/Home.htm',
            },
            {
                title: 'Quick Start',
                text: 'Motili Quick Start Guide',
                href:
                    'https://help.motilistage.com/Content/Accept%20a%20Job/Quick%20Start.htm',
            },
            {
                title: 'Submit a Work Order',
                text: 'How to Submit a Work Order',
                href:
                    'https://help.motilistage.com/Content/Use%20the%20Contractor%20Platform/Complete%20a%20Work%20Order/Submit%20the%20Work%20Order.htm',
            },
        ],
        tokenValidation: {
            backendInterval: 5, //minute
            timeBeforeExpired: 5, // minute
        },
        territory: {
            branches: BRANCHES,
            regions: REGIONS,
        },
        showTerritoryClients: SHOW_TERRITORY_CLIENTS,
        defaultAssetTaggingFee: DEFAULT_ASSET_TAGGING_FEE,
        defaultShippingSearchWebsite:
            'https://www.parcelmonitor.com/track-united-states//',
        invoiceRemitTo: INVOICE_REMIT_TO,
        liftGateSku: LIFTGATE_SKU,
        aws: {
            s3: {
                motiliFiles: {
                    bucketName: 'dev-motili-files',
                    identityPoolId:
                        'us-west-2:32b7f8c5-cea7-4a57-8fb5-2c71aff6a6e5',
                    region: 'us-west-2',
                },
            },
        },
    },
    dev2cs: {
        googleAnalyticsID: 'UA-154690269-1',
        environment: WP_ENVIRONMENT,
        release: RELEASE,
        version: VERSION,
        apiBaseUrl: 'https://sandbox-api.motilidev.com/api',
        mossApiBaseUrl: 'https://sandbox-api.motilidev.com/api/moss/',
        requirementsBuilderApi: 'NO URL!',
        baseCDNURL : 'https://static.motili.com/',
        storageNameSpace: STORAGE_NAMESPACE,
        sentry: SENTRY,
        color: COLOR,
        routing: ROUTING,
        productSearchFuseOptions: PRODUCT_SEARCH_FUSE_OPTIONS,
        modalProductSearchFuseOptions: MODAL_PRODUCT_SEARCH_FUSE_OPTIONS,
        managedPricingSearchFuseOptions: MANAGED_PRICING_SEARCH_FUSE_OPTIONS,
        managedVendorSearchFuseOptions: MANAGED_VENDOR_SEARCH_FUSE_OPTIONS,
        systemAssetConfigurationSearchFuseOptions: SYSTEM_ASSET_CONFIGURATION_SEARCH_FUSE_OPTIONS,
        autoSyncFinance: true,
        bulkCreation: BULK_CREATION,
        states: STATES,
        baseQrCodeUrl: 'assets.motilidev.com/tag/',
        helpLinks: [
            {
                title: 'Help Center',
                text: 'Visit the Motili Help Center',
                href: 'https://help.motilistage.com/Content/Home.htm',
            },
            {
                title: 'Quick Start',
                text: 'Motili Quick Start Guide',
                href:
                    'https://help.motilistage.com/Content/Accept%20a%20Job/Quick%20Start.htm',
            },
            {
                title: 'Submit a Work Order',
                text: 'How to Submit a Work Order',
                href:
                    'https://help.motilistage.com/Content/Use%20the%20Contractor%20Platform/Complete%20a%20Work%20Order/Submit%20the%20Work%20Order.htm',
            },
        ],
        tokenValidation: {
            uiInterval: 5, //minute
            backendInterval: 5, //minute
            timeBeforeExpired: 5, // minute
        },
        territory: {
            branches: BRANCHES,
            regions: REGIONS,
        },
        showTerritoryClients: SHOW_TERRITORY_CLIENTS,
        defaultAssetTaggingFee: DEFAULT_ASSET_TAGGING_FEE,
    },
    qa: {
        googleAnalyticsID: 'UA-154690269-2',
        environment: WP_ENVIRONMENT,
        release: RELEASE,
        version: VERSION,
        apiBaseUrl: 'https://s2.motilitest.com/api',
        mossApiBaseUrl: 'https://s2.motilitest.com/api/moss/',
        motiliNotificationServiceApiBaseUrl: 'NO URL!',
        requirementsBuilderApi: 'https://api.qa2.motilitest.com/rb/api',
        baseCDNURL : 'https://static.motili.com/',
        storageNameSpace: STORAGE_NAMESPACE,
        sentry: SENTRY,
        color: COLOR,
        routing: ROUTING,
        productSearchFuseOptions: PRODUCT_SEARCH_FUSE_OPTIONS,
        modalProductSearchFuseOptions: MODAL_PRODUCT_SEARCH_FUSE_OPTIONS,
        managedPricingSearchFuseOptions: MANAGED_PRICING_SEARCH_FUSE_OPTIONS,
        managedVendorSearchFuseOptions: MANAGED_VENDOR_SEARCH_FUSE_OPTIONS,
        systemAssetConfigurationSearchFuseOptions: SYSTEM_ASSET_CONFIGURATION_SEARCH_FUSE_OPTIONS,
        autoSyncFinance: true,
        bulkCreation: BULK_CREATION,
        states: STATES,
        baseQrCodeUrl: 'assets.motilitest.com/tag/',
        helpLinks: [
            {
                title: 'Help Center',
                text: 'Visit the Motili Help Center',
                href: 'https://help.motilistage.com/Content/Home.htm',
            },
            {
                title: 'Quick Start',
                text: 'Motili Quick Start Guide',
                href:
                    'https://help.motilistage.com/Content/Accept%20a%20Job/Quick%20Start.htm',
            },
            {
                title: 'Submit a Work Order',
                text: 'How to Submit a Work Order',
                href:
                    'https://help.motilistage.com/Content/Use%20the%20Contractor%20Platform/Complete%20a%20Work%20Order/Submit%20the%20Work%20Order.htm',
            },
        ],
        tokenValidation: {
            backendInterval: 5, //minute
            timeBeforeExpired: 5, // minute
        },
        territory: {
            branches: BRANCHES,
            regions: REGIONS,
        },
        showTerritoryClients: SHOW_TERRITORY_CLIENTS,
        defaultAssetTaggingFee: DEFAULT_ASSET_TAGGING_FEE,
        defaultShippingSearchWebsite:
            'https://www.parcelmonitor.com/track-united-states//',
        invoiceRemitTo: INVOICE_REMIT_TO,
        liftGateSku: LIFTGATE_SKU,
        aws: {
            s3: {
                motiliFiles: {
                    bucketName: 'qa-motili-files',
                    identityPoolId:
                        'us-west-2:4b17ee30-43d0-49a6-ae09-b399188a2ca0',
                    region: 'us-west-2',
                },
            },
        },
    },
    jenkins: {
        googleAnalyticsID: 'UA-154690269-2',
        environment: WP_ENVIRONMENT,
        release: RELEASE,
        version: VERSION,
        apiBaseUrl: 'https://api.slalom-dev.motili.link/api',
        mossApiBaseUrl: 'https://api.slalom-dev.motili.link/api/moss',
        motiliNotificationServiceApiBaseUrl: 'NO URL!',
        requirementsBuilderApi: 'https://api.slalom-dev.motili.link/rb/api',
        baseCDNURL : 'https://static.motili.com/',
        storageNameSpace: STORAGE_NAMESPACE,
        sentry: SENTRY,
        color: COLOR,
        routing: ROUTING,
        productSearchFuseOptions: PRODUCT_SEARCH_FUSE_OPTIONS,
        modalProductSearchFuseOptions: MODAL_PRODUCT_SEARCH_FUSE_OPTIONS,
        managedPricingSearchFuseOptions: MANAGED_PRICING_SEARCH_FUSE_OPTIONS,
        managedVendorSearchFuseOptions: MANAGED_VENDOR_SEARCH_FUSE_OPTIONS,
        systemAssetConfigurationSearchFuseOptions: SYSTEM_ASSET_CONFIGURATION_SEARCH_FUSE_OPTIONS,
        autoSyncFinance: true,
        bulkCreation: BULK_CREATION,
        states: STATES,
        baseQrCodeUrl: 'https://assets.slalom-dev.motili.link/tag/',
        helpLinks: [
            {
                title: 'Help Center',
                text: 'Visit the Motili Help Center',
                href: 'https://help.slalom-dev.motili.link/Content/Home.htm',
            },
            {
                title: 'Quick Start',
                text: 'Motili Quick Start Guide',
                href: 'https://help.slalom-dev.motili.link/Content/Accept%20a%20Job/Quick%20Start.htm',
            },
            {
                title: 'Submit a Work Order',
                text: 'How to Submit a Work Order',
                href:
                    'https://help.slalom-dev.motili.link/Content/Use%20the%20Contractor%20Platform/Complete%20a%20Work%20Order/Submit%20the%20Work%20Order.htm',
            },
        ],
        tokenValidation: {
            backendInterval: 5, //minute
            timeBeforeExpired: 5, // minute
        },
        territory: {
            branches: BRANCHES,
            regions: REGIONS,
        },
        showTerritoryClients: SHOW_TERRITORY_CLIENTS,
        defaultAssetTaggingFee: DEFAULT_ASSET_TAGGING_FEE,
        defaultShippingSearchWebsite:
            'https://www.parcelmonitor.com/track-united-states//',
        invoiceRemitTo: INVOICE_REMIT_TO,
        liftGateSku: LIFTGATE_SKU,
        aws: {
            s3: {
                motiliFiles: {
                    bucketName: 'slalom-dev-859862577465-files',
                    identityPoolId: 'us-west-2:87636d0f-6673-4782-83a4-b873c769be48',
                    region: 'us-west-2',
                },
            },
        },
    },
    stage: {
        googleAnalyticsID: 'UA-154690269-3',
        environment: WP_ENVIRONMENT,
        release: RELEASE,
        version: VERSION,
        apiBaseUrl: 'https://s2.motilistage.com/api',
        mossApiBaseUrl: 'https://s2.motilistage.com/api/moss/',
        motiliNotificationServiceApiBaseUrl: 'NO URL!',
        requirementsBuilderApi: 'https://api.staging2.motilistage.com/rb/api',
        baseCDNURL : 'https://static.motili.com/',
        storageNameSpace: STORAGE_NAMESPACE,
        sentry: SENTRY,
        color: COLOR,
        routing: ROUTING,
        productSearchFuseOptions: PRODUCT_SEARCH_FUSE_OPTIONS,
        modalProductSearchFuseOptions: MODAL_PRODUCT_SEARCH_FUSE_OPTIONS,
        managedPricingSearchFuseOptions: MANAGED_PRICING_SEARCH_FUSE_OPTIONS,
        managedVendorSearchFuseOptions: MANAGED_VENDOR_SEARCH_FUSE_OPTIONS,
        systemAssetConfigurationSearchFuseOptions: SYSTEM_ASSET_CONFIGURATION_SEARCH_FUSE_OPTIONS,
        autoSyncFinance: true,
        bulkCreation: BULK_CREATION,
        states: STATES,
        baseQrCodeUrl: 'assets.motilistage.com/tag/',
        helpLinks: [
            {
                title: 'Help Center',
                text: 'Visit the Motili Help Center',
                href: 'https://help.motilistage.com/Content/Home.htm',
            },
            {
                title: 'Quick Start',
                text: 'Motili Quick Start Guide',
                href:
                    'https://help.motilistage.com/Content/Accept%20a%20Job/Quick%20Start.htm',
            },
            {
                title: 'Submit a Work Order',
                text: 'How to Submit a Work Order',
                href:
                    'https://help.motilistage.com/Content/Use%20the%20Contractor%20Platform/Complete%20a%20Work%20Order/Submit%20the%20Work%20Order.htm',
            },
        ],
        tokenValidation: {
            backendInterval: 5, //minute
            timeBeforeExpired: 5, // minute
        },
        territory: {
            branches: BRANCHES,
            regions: REGIONS,
        },
        showTerritoryClients: SHOW_TERRITORY_CLIENTS,
        defaultAssetTaggingFee: DEFAULT_ASSET_TAGGING_FEE,
        defaultShippingSearchWebsite:
            'https://www.parcelmonitor.com/track-united-states//',
        invoiceRemitTo: INVOICE_REMIT_TO,
        liftGateSku: LIFTGATE_SKU,
        aws: {
            s3: {
                motiliFiles: {
                    bucketName: 'stage-motili-files',
                    identityPoolId:
                        'us-west-2:87636d0f-6673-4782-83a4-b873c769be48',
                    region: 'us-west-2',
                },
            },
        },
    },
    prod: {
        googleAnalyticsID: 'UA-64678121-12',
        environment: WP_ENVIRONMENT,
        release: RELEASE,
        version: VERSION,
        apiBaseUrl: 'https://s2.motili.com/api',
        mossApiBaseUrl: 'https://s2.motili.com/api/moss/',
        motiliNotificationServiceApiBaseUrl: 'NO URL!',
        requirementsBuilderApi: 'https://api.motili.com/rb/api',
        baseCDNURL : 'https://static.motili.com/',
        storageNameSpace: STORAGE_NAMESPACE,
        sentry: SENTRY,
        color: COLOR,
        routing: ROUTING,
        productSearchFuseOptions: PRODUCT_SEARCH_FUSE_OPTIONS,
        modalProductSearchFuseOptions: MODAL_PRODUCT_SEARCH_FUSE_OPTIONS,
        managedPricingSearchFuseOptions: MANAGED_PRICING_SEARCH_FUSE_OPTIONS,
        managedVendorSearchFuseOptions: MANAGED_VENDOR_SEARCH_FUSE_OPTIONS,
        systemAssetConfigurationSearchFuseOptions: SYSTEM_ASSET_CONFIGURATION_SEARCH_FUSE_OPTIONS,
        autoSyncFinance: true,
        bulkCreation: BULK_CREATION,
        states: STATES,
        baseQrCodeUrl: 'assets.motili.com/tag/',
        helpLinks: [
            {
                title: 'Help Center',
                text: 'Visit the Motili Help Center',
                href: 'https://help.motili.com/Content/Home.htm',
            },
            {
                title: 'Quick Start',
                text: 'Motili Quick Start Guide',
                href:
                    'https://help.motili.com/Content/Accept%20a%20Job/Quick%20Start.htm',
            },
            {
                title: 'Submit a Work Order',
                text: 'How to Submit a Work Order',
                href:
                    'https://help.motili.com/Content/Use%20the%20Contractor%20Platform/Complete%20a%20Work%20Order/Submit%20the%20Work%20Order.htm',
            },
        ],
        tokenValidation: {
            backendInterval: 5, // minute
            timeBeforeExpired: 5, // minute
        },
        territory: {
            branches: BRANCHES,
            regions: REGIONS,
        },
        showTerritoryClients: SHOW_TERRITORY_CLIENTS,
        defaultAssetTaggingFee: DEFAULT_ASSET_TAGGING_FEE,
        defaultShippingSearchWebsite:
            'https://www.parcelmonitor.com/track-united-states//',
        invoiceRemitTo: INVOICE_REMIT_TO,
        liftGateSku: LIFTGATE_SKU,
        aws: {
            s3: {
                motiliFiles: {
                    bucketName: 'prod-motili-files',
                    identityPoolId:
                        'us-west-2:09a211d4-e03f-4046-be15-ff2d4b351dd8',
                    region: 'us-west-2',
                },
            },
        },
    },
};

export default config[WP_ENVIRONMENT];
