/**
 * Copyright Motili Inc., 2016 All Rights Reserved
 */

import React from 'react';
import { Button, Image } from 'react-bootstrap';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import FormEmailInput from '../../components/form/FormEmailInput-deprecated';
import FormPasswordInput from '../../components/form/FormPasswordInput';

import background from '../../../images/splash2.png';
import logo from '../../../images/Motili-Logo-White.svg';

export default function Login(props) {
    return (
        <div style={styles.background}>
            <div style={styles.formContainer}>
                <div style={{ flex: 0.45 }}>
                    <div className='login-form-top'>
                        <Image
                            src={logo}
                            responsive
                            className='login-form-logo'
                            style={{ height: 150 }}
                        />
                    </div>
                    <div className='login-form-bottom'>
                        <TransitionGroup>
                            {props.forgotPassword ? (
                                <CSSTransition
                                    key={'login'}
                                    timeout={300}
                                    classNames={'example'}
                                >
                                    <ForgotPasswordForm {...props} />
                                </CSSTransition>
                            ) : (
                                <CSSTransition
                                    key={'forgot'}
                                    timeout={300}
                                    classNames={'example'}
                                >
                                    <LoginForm {...props} />
                                </CSSTransition>
                            )}
                        </TransitionGroup>
                    </div>
                </div>
            </div>
        </div>
    );
}

function LoginForm(props) {
    return (
        <div>
            <form key='login' onSubmit={props.handleLogin}>
                <div style={{ marginBottom: 5 }}>
                    <FormEmailInput
                        noLabel
                        value={props.email}
                        bsSize={'large'}
                        onChange={e => {
                            if (props.password) {
                                props.handleEmailChange({
                                    email: e.target.value,
                                    disabled: false,
                                });
                            } else {
                                props.handleEmailChange({
                                    email: e.target.value,
                                });
                            }
                        }}
                    />
                </div>
                <div style={{ marginBottom: 5 }}>
                    <FormPasswordInput
                        noLabel
                        bsSize={'large'}
                        value={props.password}
                        onChange={e => {
                            if (props.email) {
                                props.handlePasswordChange({
                                    password: e.target.value,
                                    disabled: false,
                                });
                            } else {
                                props.handlePasswordChange({
                                    password: e.target.value,
                                });
                            }
                        }}
                    />
                </div>
                <div style={{ marginBottom: 10 }}>
                    <Button
                        bsStyle='primary'
                        bsSize='large'
                        type='submit'
                        block
                        disabled={props.loading}
                    >
                        {props.loading ? 'Signing In ...' : 'Login'}
                    </Button>
                </div>
                <div
                    style={styles.textLink}
                    onClick={() => props.handleForgotPasswordLinkClicked(true)}
                >
                    Forgot password?
                </div>
            </form>
        </div>
    );
}

function ForgotPasswordForm(props) {
    return (
        <form
            key='forgotPassword'
            onSubmit={props.handleForgotPassword}
            style={{ marginTop: 20 }}
        >
            <div style={{ marginBottom: 5 }}>
                <FormEmailInput
                    noLabel
                    value={props.forgotPasswordEmail}
                    bsSize={'large'}
                    onChange={e => {
                        props.handleForgotPasswordEmailChange({
                            email: e.target.value,
                        });
                    }}
                />
            </div>
            <div style={{ marginBottom: 10 }}>
                <Button
                    bsStyle='primary'
                    bsSize='large'
                    type='submit'
                    block
                    disabled={props.loading}
                >
                    {props.loading ? 'Submitting ...' : 'Reset Password'}
                </Button>
            </div>

            <div
                style={styles.textLink}
                onClick={() => props.handleForgotPasswordLinkClicked(false)}
            >
                Return to account login
            </div>
        </form>
    );
}

const styles = {
    background: {
        height: '100vh',
        minWidth: 768,
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        flex: 1,
    },
    formContainer: {
        flexDirection: 'row',
        display: 'flex',
        flex: 0.75,
        justifyContent: 'space-around',
    },
    textLink: {
        cursor: 'pointer',
        color: 'white',
        fontSize: 14,
    },
};
