/**
 * @license
 * @copyright Copyright Motili Inc., 2017 All Rights Reserved
 */

import sortBy from 'lodash/sortBy';
import unionBy from 'lodash/unionBy';
import map from 'lodash/map';
import * as ApiService from './ApiService';

export default {
    timezones,
    country,
    states,
    usStates,
    postalCodes,
    account: {
        opsAccounts: opsAccounts,
        preferenceTypes: accountPreferenceTypes,
        roles: accountMotiliRoles,
        roleTypes: accountRoleTypes,
        departmentTypes: accountDepartmentTypes,
        types: accountTypes,
        statuses: accountStatuses,
    },
    client: {
        clients: clients,
        statuses: clientStatuses,
        settings: clientSettings,
        freightTypes: freightTypes,
        integrationTypes: clientIntegrationTypes,
        statusChangedNotificationConfig: clientStatusChangedNotificationConfig,
        businessChannels: clientBusinessChannels,
        orderIssues: clientOrderIssues,
    },
    asset: {
        assetStatuses: assetStatuses,
        photoTypes: assetPhotoTypes,
        retirementReasons: assetRetirementReasons,
        assetConfigurationCategories: assetConfigurationCategories,
        untaggableReasons: assetUntaggableReasons,
    },
    contractor: {
        statuses: contractorStatuses,
        qualificationTypes: contractorQualificationTypes,
        member: {
            notifications: contractorNotifications,
        },
        serviceTypes: contractorServiceTypes,
        credentials: contractorCredentials,
        certifications: contractorCertifications,
        clients: contractorClients,
        blocklistReasons: contractorBlocklistReasons,
    },
    document: {
        types: documentTypes,
        tradeTypes: documentTradeTypes,
        photoTypes: documentPhotoTypes,
        priorityTypes: documentPriorityTypes,
        contactTypes: documentContactTypes,
        fulfillmentTypes: documentFulfillmentTypes,
        attachmentTypes: documentAttachmentTypes,
        assetCategories: documentAssetCategories,
        workOrder: {
            statuses: workOrderStatuses,
            types: workOrderTypes,
            pdfTypes: workOrderPdfTypes,
            issues: workOrderIssues,
        },
        quote: {
            statuses: quoteStatuses,
            types: quoteTypes,
            reasons: quoteReasons,
            pdfTypes: quotePdfTypes,
        },
        order: {
            statuses: orderStatuses,
            types: orderTypes,
            fulfillmentMethods: orderFulfillmentMethods,
            contactTypes: orderContactTypes,
            notificationTypes: notificationTypes,
            logisticsTypes: logisticsTypes,
            returnStatuses: orderReturnStatuses,
            returnReasons: orderReturnReasons,
            returnCancelReasons: orderReturnCancelReasons,
            issues: orderIssues,
        },
        draft: {
            statuses: draftStatuses,
        },
    },
    product: {
        categories: productCategories,
        attributes: productAttributes,
        pricingTypes: productPricingTypes,
        unitOfMeasures: unitOfMeasures,
    },
    project: {
        contactTypes: projectContactTypes,
        statuses: projectStatuses,
        types: projectTypes,
        scopeTypes: projectScopeTypes,
    },
    property: {
        properties: properties,
        propertyTypes: propertyTypes,
        propertyStatuses: propertyStatuses,
    },
    financial: {
        terms: terms,
        billingStatuses: billingStatuses,
        paymentStatuses: paymentStatuses,
        statuses: financialStatuses,
    },
    ticket: {
        providers: ticketProviders,
    },
    vendor: {
        allVendor: allVendor,
        vendors: vendors,
        manufacturers: manufacturers,
        statuses: vendorStatuses,
        integrationTypes: integrationTypes,
        distributionCenter: {
            dcConfigurations: dcConfigurations,
        },
    },
    template: {
        types: templateTypes,
    },
};

function timezones() {
    const query = {
        filter: {
            where: {
                or: [{ country: 'US' }, { country: 'CA' }],
            },
            order: ['display ASC'],
        },
    };
    return ApiService.get(true, 'TimeZones', query, false);
}

function country() {
    return ApiService.get(true, 'Countries', {}, false);
}

function states() {
    return ApiService.get(true, 'States', {}, false);
}

function usStates() {
    const query = {
        filter: {
            fields: ['id', 'display', 'counties'],
            order: ['display ASC'],
        },
    };
    return ApiService.get(true, 'UsStates', query, false);
}

function postalCodes() {
    const query = {
        filter: {
            fields: ['postalCode', 'placeName'],
            order: ['postalCode ASC'],
        },
    };
    return ApiService.get(true, 'PostalCodes', query, false);
}

/**
 * gets list of motili operations accounts
 * caution - motili_* only
 * @returns {Promise<Object}
 */
function opsAccounts() {
    return ApiService.get(true, 'accounts', {
        filter: {
            where: {
                active: true,
                userType: 'motili',
            },
            order: ['firstName ASC', 'lastName ASC'],
            fields: [
                'id',
                'firstName',
                'lastName',
                'fullName',
                'department',
                'email',
            ],
        },
    });
}

function accountPreferenceTypes() {
    return ApiService.get(
        true,
        'accounts/preference-types',
        undefined,
        false
    ).then(preferenceTypes => sortBy(preferenceTypes, ['display']));
}

function accountMotiliRoles() {
    const query = {
        filter: {
            fields: ['name', 'description'],
        },
    };
    return ApiService.get(true, 'Roles', query, false).then(roles =>
        roles.map(r => ({
            id: r.name,
            name: r.name,
            display: r.description,
            description: r.description,
        }))
    );
}

function accountRoleTypes() {
    return ApiService.get(true, 'Accounts/roleTypes', false);
}

function accountStatuses() {
    return ApiService.get(true, 'Accounts/statuses', undefined, false);
}

function assetStatuses() {
    return ApiService.get(
        true,
        'Assets/statuses',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

function assetPhotoTypes() {
    return ApiService.get(
        true,
        'Assets/photoTypes',
        undefined,
        false
    ).then(photoTypes => sortBy(photoTypes, ['display']));
}

function assetRetirementReasons() {
    return ApiService.get(true, 'Assets/retirementReasons', undefined, false);
}

function assetConfigurationCategories() {
    return ApiService.get(
        true,
        'AssetConfigurations/categories',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

function assetUntaggableReasons() {
    return ApiService.get(true, 'Assets/untaggableReasons', undefined, false);
}

function documentTypes() {
    return ApiService.get(
        true,
        'Documents/types',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

function documentTradeTypes() {
    return ApiService.get(true, 'WorkOrders/tradeTypes', undefined, false)
        .then(tradeTypes => sortBy(tradeTypes, ['display']))
        .then(tradeTypes =>
            tradeTypes.map(tradeType =>
                Object.assign({}, tradeType, {
                    tradeSubTypes: sortBy(tradeType.tradeSubTypes, ['display']),
                })
            )
        );
}

function documentPhotoTypes() {
    return ApiService.get(
        true,
        'WorkOrders/photoTypes',
        undefined,
        false
    ).then(photoTypes => sortBy(photoTypes, ['display']));
}

function documentAssetCategories() {
    return ApiService.get(
        true,
        'AssetConfigurations/categories',
        undefined,
        false
    ).then(categories => {
        return sortBy(categories, ['display']);
    });
}

function documentPriorityTypes() {
    return ApiService.get(
        true,
        'WorkOrders/priorities',
        undefined,
        false
    ).then(priorityTypes => sortBy(priorityTypes, ['display']));
}

function documentContactTypes() {
    return ApiService.get(
        true,
        'WorkOrders/contactTypes',
        undefined,
        false
    ).then(contactTypes => sortBy(contactTypes, ['display']));
}

function documentFulfillmentTypes() {
    return ApiService.get(
        true,
        'Orders/fulfillmentTypes',
        undefined,
        false
    ).then(fulfillmentTypes => sortBy(fulfillmentTypes, ['display']));
}

function documentAttachmentTypes() {
    return ApiService.get(
        true,
        'Folders/attachmentTypes',
        undefined,
        false
    ).then(attachmentTypes => sortBy(attachmentTypes, ['display']));
}

function workOrderStatuses() {
    return ApiService.get(
        true,
        'WorkOrders/statuses',
        undefined,
        false
    ).then(statuses => sortBy(statuses, ['display']));
}

function workOrderTypes() {
    return ApiService.get(
        true,
        'WorkOrders/types',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

function workOrderPdfTypes() {
    return ApiService.get(
        true,
        'WorkOrders/pdfTypes',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

function quoteStatuses() {
    return ApiService.get(
        true,
        'Quotes/statuses',
        undefined,
        false
    ).then(statuses => sortBy(statuses, ['display']));
}

function quoteTypes() {
    return ApiService.get(true, 'Quotes/types', undefined, false).then(types =>
        sortBy(types, ['display'])
    );
}

function quoteReasons() {
    return ApiService.get(true, 'Quotes/reasons', undefined, false);
}

function quotePdfTypes() {
    return ApiService.get(
        true,
        'Quotes/quotePdfTypes',
        undefined,
        false
    ).then(pdfTypes => sortBy(pdfTypes, ['display']));
}

function orderStatuses() {
    return ApiService.get(
        true,
        'Orders/statuses',
        undefined,
        false
    ).then(statuses => sortBy(statuses, ['display']));
}

function orderIssues() {
    return ApiService.get(
        true,
        'Orders/issues',
        undefined,
        false
    ).then(statuses => sortBy(statuses, ['display']));
}

function workOrderIssues() {
    return ApiService.get(
        true,
        'WorkOrders/issues',
        undefined,
        false
    ).then(statuses => sortBy(statuses, ['display']));
}

function orderTypes() {
    return ApiService.get(true, 'Orders/types', undefined, false).then(types =>
        sortBy(types, ['display'])
    );
}

function orderFulfillmentMethods() {
    return ApiService.get(
        true,
        'Orders/fulfillmentMethods',
        undefined,
        false
    ).then(fulfillmentMethods => sortBy(fulfillmentMethods, ['display']));
}

function orderContactTypes() {
    return ApiService.get(
        true,
        'Orders/contactTypes',
        undefined,
        false
    ).then(contactTypes => sortBy(contactTypes, ['display']));
}

function draftStatuses() {
    return ApiService.get(
        true,
        'Drafts/statuses',
        undefined,
        false
    ).then(statuses => sortBy(statuses, ['display']));
}

function notificationTypes() {
    return ApiService.get(
        true,
        'Orders/notificationTypes',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

function logisticsTypes() {
    return ApiService.get(
        true,
        'Orders/logisticsTypes',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

async function orderReturnStatuses() {
    const statuses = await ApiService.get(
        true,
        'Orders/orderReturnStatuses',
        undefined,
        false
    );
    return buildListWithOther(statuses, ['OTHER'], ['display']);
}

async function orderReturnReasons() {
    const reasons = await ApiService.get(
        true,
        'Orders/orderReturnReasons',
        undefined,
        false
    );
    return buildListWithOther(reasons, ['OTHER'], ['display']);
}

async function orderReturnCancelReasons() {
    const reasons = await ApiService.get(
        true,
        'Orders/orderReturnCancelReasons',
        undefined,
        false
    );
    return buildListWithOther(reasons, ['OTHER'], ['display']);
}

function accountDepartmentTypes() {
    return ApiService.get(
        true,
        'Accounts/departmentTypes',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

function accountTypes() {
    return ApiService.get(
        true,
        'Accounts/types',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

function terms() {
    return ApiService.get(true, 'Terms/terms', undefined, false).then(types =>
        sortBy(types, ['display'])
    );
}

function billingStatuses() {
    return ApiService.get(
        true,
        'Billings/statuses',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

function paymentStatuses() {
    return ApiService.get(
        true,
        'Payments/statuses',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

async function financialStatuses() {
    const _billingStatuses = await ApiService.get(
        true,
        'Billings/statuses',
        undefined,
        false
    );
    const _paymentStatuses = await ApiService.get(
        true,
        'Payments/statuses',
        undefined,
        false
    );
    return sortBy(unionBy(_billingStatuses, _paymentStatuses, 'display'), [
        'display',
    ]);
}

/**
 * gets list of clients for client drop down lists
 * caution - motili_* only
 * @returns {Promise<Object}
 */
function clients() {
    return ApiService.get(true, 'Clients', {
        filter: {
            where: {
                statusId: {
                    inq: ['APPROVED', 'ENABLED'],
                },
            },
            order: ['name ASC'],
            fields: [
                'id',
                'name',
                'doingBusinessAs',
                'statusId',
                'website',
                'addressId',
            ],
            include: [
                {
                    relation: 'address',
                    scope: {
                        fields: [
                            'stdDeliveryLine',
                            'stdLastLine',
                            'standardized',
                            'rawAddress',
                        ],
                    },
                },
                {
                    relation: 'settings',
                    scope: {
                        fields: ['settingTypeId', 'setting'],
                        where: {
                            settingTypeId: 'LINE_OF_BUSINESS',
                        },
                    },
                },
            ],
        },
    });
}

function clientStatuses() {
    return ApiService.get(
        true,
        'Clients/statuses',
        undefined,
        false
    ).then(statuses => sortBy(statuses, ['display']));
}

function clientSettings() {
    return ApiService.get(
        true,
        'Clients/settingTypes',
        undefined,
        false
    ).then(statuses => sortBy(statuses, ['display']));
}

function freightTypes() {
    return ApiService.get(
        true,
        'Clients/freightTypes',
        undefined,
        false
    ).then(statuses => sortBy(statuses, ['display']));
}

function clientIntegrationTypes() {
    return ApiService.get(
        true,
        'Clients/integrationTypes',
        undefined,
        false
    ).then(integrations => sortBy(integrations, ['display']));
}

function clientStatusChangedNotificationConfig() {
    return ApiService.get(
        true,
        'Clients/statusChangedNotificationConfig',
        undefined,
        false
    );
}

function clientBusinessChannels() {
    return ApiService.get(
        true,
        'Clients/businessChannels',
        undefined,
        false
    ).then(channels => sortBy(channels, ['display']));
}

function clientOrderIssues() {
    return ApiService.get(
        true,
        'Clients/orderIssues',
        undefined,
        false
    ).then(issues => sortBy(issues, ['display']));
}

function contractorQualificationTypes() {
    return ApiService.get(
        true,
        'Contractors/qualificationTypes',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

function contractorNotifications() {
    return ApiService.get(
        true,
        'Contractors/teamMemberNotifications',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

function contractorStatuses() {
    return ApiService.get(
        true,
        'Contractors/statuses',
        undefined,
        false
    ).then(statuses => sortBy(statuses, ['display']));
}

async function contractorServiceTypes() {
    const serviceTypes = await ApiService.get(
        true,
        'Contractors/serviceTypes',
        undefined,
        false
    );
    //Sorts serviceTypes alphabetically by keys, sortBy function loses keys so invalid here
    return Object.keys(serviceTypes)
        .sort()
        .reduce((obj, key) => {
            obj[key] = serviceTypes[key];
            return obj;
        }, {});
}
function contractorCredentials() {
    return ApiService.get(true, 'Contractors/credentials', undefined, false);
}
function contractorCertifications() {
    return ApiService.get(true, 'Contractors/certifications', undefined, false);
}

function contractorClients(contractorId) {
    if (!contractorId) {
        return Promise.resolve([]);
    }
    return ApiService.get(
        true,
        `Contractors/${contractorId}/clients`,
        undefined,
        false
    );
}

function contractorBlocklistReasons() {
    return ApiService.get(
        true,
        'Contractors/blocklistReasons',
        undefined,
        false
    );
}

export function productCategories() {
    return ApiService.get(true, 'products/categories')
        .then(categories => sortBy(categories, ['display']))
        .then(categories =>
            categories.map(category =>
                Object.assign({}, category, {
                    subCategories: sortBy(category.subCategories, ['display']),
                })
            )
        );
}

function properties() {
    return ApiService.get(true, 'Properties', {
        filter: {
            where: {
                active: true,
            },
            order: ['rawAddress ASC'],
            fields: ['id', 'name', 'rawAddress'],
        },
    });
}

function propertyTypes() {
    return ApiService.get(
        true,
        'Properties/types',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

function propertyStatuses() {
    return ApiService.get(
        true,
        'Properties/statuses',
        undefined,
        false
    ).then(statuses => sortBy(statuses, ['display']));
}

export function productAttributes() {
    return ApiService.get(true, 'products/attributeTypes')
        .then(attributes => sortBy(attributes, ['display']))
        .then(attributes =>
            attributes.map(attribute =>
                Object.assign({}, attribute, {
                    subTypes: sortBy(attribute.subTypes, ['display']),
                })
            )
        );
}

export function productPricingTypes() {
    return ApiService.get(true, 'managedpricing/types').then(types =>
        sortBy(types, 'display')
    );
}

export function unitOfMeasures() {
    return ApiService.get(true, 'managedProducts/unitOfMeasures').then(res =>
        sortBy(res, ['display'])
    );
}

export function vendors() {
    return ApiService.get(true, 'Vendors', {
        filter: {
            where: {
                statusId: 'APPROVED',
            },
            order: ['name ASC'],
            fields: [
                'id',
                'name',
                'doingBusinessAs',
                'active',
                'website',
                'addressId',
            ],
        },
    });
}

export function allVendor() {
    return ApiService.get(true, 'Vendors', {
        filter: {
            order: ['name ASC'],
            fields: [
                'id',
                'name',
                'doingBusinessAs',
                'active',
                'website',
                'addressId',
            ],
        },
    });
}

function projectContactTypes() {
    return ApiService.get(true, 'Projects/contactTypes', undefined, false);
}

function projectStatuses() {
    return ApiService.get(
        true,
        'Projects/statuses',
        undefined,
        false
    ).then(statuses => sortBy(statuses, ['display']));
}

function projectTypes() {
    return ApiService.get(true, 'Projects/types', undefined, false).then(
        types => types
    );
}

function projectScopeTypes() {
    return ApiService.get(true, 'Projects/scopeTypes', undefined, false).then(
        types => types
    );
}

export function manufacturers() {
    return ApiService.get(true, 'Vendors', {
        filter: {
            where: {
                active: true,
                typeId: 'MANUFACTURER',
            },
            order: ['name ASC'],
            fields: [
                'id',
                'name',
                'doingBusinessAs',
                'active',
                'website',
                'addressId',
            ],
        },
    });
}

export function ticketProviders() {
    return ApiService.get(
        true,
        'Tickets/providers',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

export function dcConfigurations() {
    return ApiService.get(
        true,
        'ManagedVendors/distribution/types',
        undefined,
        false
    ).then(configurations => configurations);
}

export function integrationTypes() {
    return ApiService.get(
        true,
        'ManagedVendors/integration/types',
        undefined,
        false
    ).then(_integrationTypes => _integrationTypes);
}

export function vendorStatuses() {
    return ApiService.get(
        true,
        'ManagedVendors/statuses',
        undefined,
        false
    ).then(statuses => statuses);
}

export function templateTypes() {
    return ApiService.get(true, 'Templates/types', undefined, false);
}

/**
 * combines trade and sub types into single array
 * @param {Object} tradeTypes
 * @returns {Array}
 */
export function buildTradeAndSubTypes(tradeTypes) {
    return tradeTypes.reduce((acc, next) => {
        const tradeAndSubTypesForTradeType = map(
            next.tradeSubTypes,
            subType => ({
                id: `${next.id}-${subType.id}`,
                display: `${next.display} - ${subType.display}`,
                tradeTypeId: next.id,
                tradeSubTypeId: subType.id,
            })
        );
        return acc.concat(tradeAndSubTypesForTradeType);
    }, []);
}

/**
 * Build list and move other(given otherIds) to last index.
 *
 * @param list array
 * @param otherIds array
 * @param sortKeys array
 * @return array
 */
function buildListWithOther(list, otherIds, sortKeys) {
    if (otherIds && otherIds.length > 0) {
        let others = list.filter(s => {
            return otherIds.find(id => {
                return id === s.id;
            });
        });
        if (others) {
            others = sortBy(others, sortKeys);
            let returnList = list.filter(s => {
                return !otherIds.find(id => {
                    return id === s.id;
                });
            });
            returnList = sortBy(returnList, sortKeys);
            returnList = returnList.concat(others);
            return returnList;
        } else {
            return sortBy(list, sortKeys);
        }
    } else {
        return sortBy(list, sortKeys);
    }
}
