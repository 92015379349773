/**
 * Copyright Motili Inc., 2016 All Rights Reserved
 */

import React from 'react'; // eslint-disable-line no-unused-vars
import { FadingCircle } from 'better-react-spinkit';

/**
 * Loading Screen
 */
export default function() {
    return (
        <div className='loading-overlay'>
            <FadingCircle size={100} color={'#57c1e9'} />
        </div>
    );
}
