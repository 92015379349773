/**
 * Copyright Motili Inc., 2016 All Rights Reserved
 */

import * as ApiService from './ApiService';

export function lookup(number) {
    const query = {
        number: number,
    };
    return ApiService.get(true, 'TwilioPhoneNumbers/lookup', query);
}
export function lookupWithAccessToken(number, accessToken) {
    const query = {
        number: number,
    };
    return ApiService.get(
        true,
        'TwilioPhoneNumbers/lookup',
        query,
        false,
        accessToken
    );
}

export function validateMobile(number) {
    return lookup(number)
        .then(response => {
            if (response && response.carrier && isMobileCarrier(response)) {
                return true;
            }
            return false;
        })
        .catch(error => {
            console.error(error);
            return false;
        });
}

export function validatePhone(number) {
    return lookup(number)
        .then(response => {
            if (response && response.carrier) {
                return true;
            }
            return false;
        })
        .catch(error => {
            console.error(error);
            return false;
        });
}

export function validateNumber(number) {
    const query = {
        number: number,
    };
    return ApiService.get(true, 'TwilioPhoneNumbers/isValid', query)
        .then(response => response)
        .catch(error => {
            console.log(error);
            return false;
        });
}

export async function validateMobileWithAccessToken(number, accessToken) {
    try {
        const response = await lookupWithAccessToken(number, accessToken);
        return isMobileCarrier(response);
    } catch (error) {
        console.error(error);
    }
    return false;
}

export async function validatePhoneWithAccessToken(number, accessToken) {
    try {
        const response = await lookupWithAccessToken(number, accessToken);
        return response?.carrier;
    } catch (error) {
        console.error(error);
    }
    return false;
}

function isMobileCarrier(response) {
    if (!response) return false;
    return (
        response?.carrier?.type === 'mobile' ||
        response?.carrier?.type === 'voip'
    );
}
