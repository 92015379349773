/**
 * Copyright Motili Inc., 2016 All Rights Reserved
 */

// TODO: This component has not been updated to follow the 'common' component paradigm

import React from 'react';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';

import * as Validator from '../../utils/Validator';

/**
 * FormEmailInput
 *
 * Standard Motili Email Input for use within a form
 *
 */
export default class FormEmailInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            validationState: null,
        };

        this.getValidationState = _getValidationState;
    }

    render() {
        const _label = this.props.noLabel ? null : (
            <ControlLabel style={this.props.labelStyle || {}}>
                {this.props.label || 'Email'}
            </ControlLabel>
        );

        let validationErrorMsg = null;
        if (this.state.validationState === 'error') {
            validationErrorMsg = this.props.noLabel ? null : (
                <span className='not-valid-description'>
                    Email is not valid
                </span>
            );
        }

        return (
            <FormGroup
                className='email-form'
                controlId='emailInput'
                bsSize={this.props.bsSize}
                validationState={this.state.validationState}
            >
                {_label}
                <FormControl
                    disabled={this.props.disabled}
                    type='text'
                    value={this.props.value}
                    placeholder='Email'
                    onChange={this.props.onChange}
                    onBlur={() =>
                        this.setState({
                            validationState: this.getValidationState(
                                this.props.value
                            ),
                        })
                    }
                />
                {validationErrorMsg}
            </FormGroup>
        );
    }
}

function _getValidationState(value) {
    if (Validator.isEmail(value)) {
        return 'success';
    }
    return 'error';
}
