/**
 * @license
 * @copyright Copyright Motili Inc., 2017 All Rights Reserved
 */

import React from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { FadingCircle } from 'better-react-spinkit';

import Config from '../../../Config';

import styles from './style/sass/busy-spinner.module.scss';

export default class BusySpinner extends React.Component {
    static propTypes = {
        error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
        errorMessage: PropTypes.string,
        busy: PropTypes.bool.isRequired,
        busyMessage: PropTypes.string,
        okayMessage: PropTypes.string,
        size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large'])
            .isRequired,
        containerStyle: PropTypes.object,
        color: PropTypes.string.isRequired,
    };
    static defaultProps = {
        busy: false,
        size: 'small',
        containerStyle: {},
        color: Config.color.blue,
    };
    constructor(props) {
        super(props);
        this.state = {
            okay: false,
        };
        this.timeoutId = null;
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.busy && !nextProps.busy && !nextProps.error) {
            this.setState(
                {
                    okay: true,
                },
                () => {
                    if (this.timeoutId) {
                        window.clearTimeout(this.timeoutId);
                    }
                    this.timeoutId = window.setTimeout(
                        () =>
                            this.setState({
                                okay: false,
                            }),
                        1000
                    );
                }
            );
        }
    }
    componentWillUnmount() {
        if (this.timeoutId) {
            window.clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
    }
    render() {
        return (
            <div
                className={styles['busy-spinner']}
                style={{
                    ...this.props.containerStyle,
                    height: `${sizes[this.props.size]}px`,
                }}
            >
                {this.props.busy && (
                    <div
                        className={styles.group}
                        style={{
                            color: Config.color.blue,
                            fontSize: `${sizes[this.props.size]}px`,
                        }}
                    >
                        <FadingCircle
                            size={sizes[this.props.size]}
                            color={this.props.color}
                        />
                        &nbsp;
                        {this.props.busyMessage}
                    </div>
                )}
                {this.props.error && (
                    <div
                        key={1}
                        className={styles.group}
                        style={{
                            color: Config.color.red,
                            fontSize: `${sizes[this.props.size]}px`,
                        }}
                    >
                        <i
                            style={{ color: this.props.color }}
                            className={'fa fa-frown-o'}
                        />
                        &nbsp;
                        {this.props.errorMessage}
                    </div>
                )}
                {!this.props.busy && !this.props.error && (
                    <TransitionGroup>
                        {this.state.okay && (
                            <CSSTransition
                                timeout={300}
                                classNames={'busy-spinner'}
                            >
                                <div
                                    key={2}
                                    className={styles.group}
                                    style={{
                                        fontSize: `${sizes[this.props.size]}px`,
                                    }}
                                >
                                    <i
                                        style={{ color: this.props.color }}
                                        className={'fa fa-check'}
                                    />
                                    &nbsp;
                                    {this.props.okayMessage}
                                </div>
                            </CSSTransition>
                        )}
                    </TransitionGroup>
                )}
            </div>
        );
    }
}

const sizes = {
    xsmall: 14,
    small: 18,
    medium: 24,
    large: 36,
};
