/**
 * Copyright Motili Inc., 2019 All Rights Reserved
 */

import React from 'react';
import { Redirect } from 'react-router-dom';
import Alert from 'common/components/Alert';

import AuthStore from '../../stores/AuthStore';
import AuthActions from '../../actions/AuthActions';

import {
    login as AuthServiceLogin,
    forgotPassword as AuthServiceForgotPassword,
} from '../../services/AuthService';

import Login from './Login';
import { ServiceWorkerContext } from '../../../useServiceWorker';
import { UserTokenContext } from '../../../useUserToken';

export default function LoginContainer(props) {
    const serviceWorker = React.useContext(ServiceWorkerContext);
    const userToken = React.useContext(UserTokenContext);
    return (
        <>
            <LoginContainerComponent
                {...props}
                serviceWorker={serviceWorker}
                userToken={userToken}
            />
        </>
    );
}

/**
 * Login
 *
 */
class LoginContainerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            forgotPasswordEmail: '',
            password: '',
            loading: AuthStore.getState().loading,
            authenticated: AuthStore.getState().authenticated,
            forgotPassword: false,
        };

        this.onChange = _onChange.bind(this);
        this.login = _handleLogin.bind(this);
        this.handleForgotPasswordLinkClicked = _handleForgotPasswordLinkClicked.bind(
            this
        );
        this.handleForgotPassword = _handleForgotPassword.bind(this);
    }

    componentDidMount() {
        this.unlisten = AuthStore.listen(this.onChange);
    }

    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        if (this.state.authenticated) {
            return (
                <Redirect
                    to={{ pathname: '/', search: this.props.location?.search }}
                />
            );
        }

        return (
            <Login
                handleLogin={this.login}
                login={this.login}
                email={this.state.email}
                password={this.state.password}
                handleEmailChange={({ email, disabled }) =>
                    this.setState({ email, disabled })
                }
                handlePasswordChange={({ password, disabled }) =>
                    this.setState({ password, disabled })
                }
                loading={this.state.loading}
                forgotPasswordEmail={this.state.forgotPasswordEmail}
                forgotPassword={this.state.forgotPassword}
                handleForgotPasswordEmailChange={({ email }) =>
                    this.setState({ forgotPasswordEmail: email })
                }
                handleForgotPasswordLinkClicked={
                    this.handleForgotPasswordLinkClicked
                }
                handleForgotPassword={this.handleForgotPassword}
            />
        );
    }
}

function _onChange(state) {
    this.setState(
        {
            loading: state.loading,
            authenticated: state.authenticated,
        },
        () => {
            if (state.message) {
                return Alert.success(state.message, {
                    position: 'top',
                    timeout: 3000,
                });
            }
            if (state.errorMessage && state.errorMessage.statusCode === 401) {
                return Alert.error(
                    'The provided email and password were not recognized, please try again. Please contact support@motili.com if you need assistance logging into your account.',
                    {
                        position: 'top',
                        timeout: 3000,
                    }
                );
            }

            if (state.errorMessage) {
                return Alert.error(
                    'There was an error processing the request, please contact support@motili.com if you need assistance logging into your account.',
                    {
                        position: 'top',
                        timeout: 3000,
                    }
                );
            }
        }
    );
}

async function _handleLogin(e) {
    e.preventDefault();
    this.setState({
        loading: true,
    });
    try {
        // check if new version available
        const { updateServiceWorker } = this.props.serviceWorker;
        const { setLoggedIn } = this.props.userToken;
        await setLoggedIn(false);
        const loginResult = await AuthServiceLogin({
            email: this.state.email.toLowerCase().trim(),
            password: this.state.password,
        });
        if (loginResult.changePassword === true) {
            this.props.history.push('/updatePassword');
        } else {
            // call service worker update after login success
            if (updateServiceWorker) {
                // update service worker
                await updateServiceWorker(true);
            }
            AuthActions.fetchedToken(loginResult.token);
            await setLoggedIn(true);
        }
    } catch (errObject) {
        AuthActions.fetchTokenFailed(errObject);
        throw errObject;
    }
}

function _handleForgotPassword(e) {
    e.preventDefault();
    return AuthServiceForgotPassword({
        email: this.state.forgotPasswordEmail,
    })
        .then(response => {
            AuthActions.forgotPasswordSuccess(response);
            return response;
        })
        .catch(errObject => {
            AuthActions.failedForgotPassword(errObject);
            return errObject;
        });
}

function _handleForgotPasswordLinkClicked(boolean) {
    return this.setState({
        forgotPassword: boolean,
    });
}
