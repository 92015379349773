/**
 * @license
 * @copyright Copyright Motili Inc., 2019 All Rights Reserved
 */

import React from 'react'; // eslint-disable-line no-unused-vars

import Modal from './Modal';
import MotiliButton from '../buttons/MotiliButton';
import MotiliButtonContainer from '../buttons/MotiliButtonContainer';

export default function UpdateAppModal({ handleUpdate }) {
    return (
        <Modal
            controlId={'updateAppModal-sw'}
            headerStyles={{ fontSize: 32 }}
            header={'Required Update'}
            onCloseClick={handleUpdate}
            showClose={false}
            closeByDocument={false}
            footer={
                <MotiliButtonContainer>
                    <MotiliButton text={'Update Now'} onClick={handleUpdate} />
                </MotiliButtonContainer>
            }
        >
            <div>Click Update Now to perform a Platform upgrade.</div>
        </Modal>
    );
}
