/**
 * @license
 * @copyright Copyright Motili Inc., 2017 All Rights Reserved
 */

import React, { Component, Fragment } from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';

import styles from './FormLayout.module.scss';

export class FormContainer extends Component {
    static propTypes = {
        children: PropTypes.node,
        noBackground: PropTypes.bool,
        style: PropTypes.object,
        editing: PropTypes.bool,
        controlId: PropTypes.string,
    };

    static defaultProps = {
        noBackground: false,
        style: {},
        editing: false,
    };

    render() {
        return (
            <div
                className={`${styles['form-container']} ${
                    this.props.noBackground ? styles['no-background'] : ''
                } ${this.props.editing ? styles['editing'] : ''}`}
                style={this.props.style}
                id={this.props.controlId}
            >
                {this.props.children}
            </div>
        );
    }
}

export class FormSelectableContainer extends Component {
    static propTypes = {
        children: PropTypes.node,
        noBackground: PropTypes.bool,
        style: PropTypes.object,
        handleOnClick: PropTypes.func,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    };

    static defaultProps = {
        noBackground: false,
        style: {},
    };

    render() {
        return (
            <div
                className={`${styles['form-container']} ${
                    this.props.noBackground ? styles['no-background'] : ''
                }`}
                style={this.props.style}
                onClick={() => this.props.handleOnClick(this.props.id)}
            >
                {this.props.children}
            </div>
        );
    }
}

export class FormSectionHeader extends Component {
    static propTypes = {
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
            .isRequired,
        imgSrc: PropTypes.node,
        actionItems: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                handleSelect: PropTypes.func.isRequired,
                style: PropTypes.object,
            })
        ),
        buttonGroup: PropTypes.element,
        requiredFields: PropTypes.bool,
        icon: PropTypes.node,
        toolbar: PropTypes.element,
    };
    static defaultProps = {};
    render() {
        if (this.props.toolbar) {
            return (
                <div
                    className={
                        this.props.requiredFields
                            ? styles['form-section-header-with-required']
                            : styles['form-section-header']
                    }
                >
                    <div className={styles.formSectionToolbar}>
                        <div className={styles.right}>
                            <h3 className={styles.header}>{this.props.title}</h3>
                            {this.props.requiredFields && (
                                <div className={styles.requiredFieldsText}>
                                    *Required Fields
                                </div>
                            )}
                            <div className={styles.underline} />
                        </div>
                        <div className={styles.left}>
                            {this.props.toolbar}
                        </div>
                    </div>
                </div>
            );
        } else if (this.props.icon && this.props.imgSrc) {
            console.warn(
                'Too many props passed to FormSectionHeader. This component only allows either icon or imgSrc, not both.'
            );
            return (
                <div
                    className={
                        this.props.requiredFields
                            ? styles['form-section-header-with-required']
                            : styles['form-section-header']
                    }
                >
                    <div className={styles.right}>
                        <h3 className={styles.header}>{this.props.title}</h3>
                        {this.props.requiredFields && (
                            <div className={styles.requiredFieldsText}>
                                *Required Fields
                            </div>
                        )}
                        <div className={styles.underline} />
                    </div>
                    {this.props.actionItems &&
                        this.props.actionItems.map((actionItem, idx) => (
                            <Fragment key={idx}>
                                {idx > 0 && <span>&nbsp;|&nbsp;</span>}
                                <div
                                    onClick={actionItem.handleSelect}
                                    style={Object.assign(
                                        {},
                                        actionItem.style || {},
                                        {
                                            cursor: 'pointer',
                                        }
                                    )}
                                >
                                    {actionItem.name}
                                </div>
                            </Fragment>
                        ))}
                    {this.props.buttonGroup}
                </div>
            );
        } else if (!this.props.imgSrc && !this.props.icon) {
            return (
                <div
                    className={
                        this.props.requiredFields
                            ? styles['form-section-header-with-required']
                            : styles['form-section-header']
                    }
                >
                    <div className={styles.right}>
                        <h3 className={styles.header}>{this.props.title}</h3>
                        {this.props.requiredFields && (
                            <div className={styles.requiredFieldsText}>
                                *Required Fields
                            </div>
                        )}
                        <div className={styles.underline} />
                    </div>
                    {this.props.actionItems &&
                        this.props.actionItems.map((actionItem, idx) => (
                            <Fragment key={idx}>
                                {idx > 0 && <span>&nbsp;|&nbsp;</span>}
                                <div
                                    onClick={actionItem.handleSelect}
                                    style={Object.assign(
                                        {},
                                        actionItem.style || {},
                                        {
                                            cursor: 'pointer',
                                        }
                                    )}
                                >
                                    {actionItem.name}
                                </div>
                            </Fragment>
                        ))}
                    {this.props.buttonGroup}
                </div>
            );
        } else if (this.props.imgSrc) {
            return (
                <div
                    className={
                        this.props.requiredFields
                            ? styles['form-section-header-with-required']
                            : styles['form-section-header']
                    }
                >
                    {this.props.imgSrc && (
                        <div className={styles.left}>
                            <img
                                className={styles.image}
                                src={this.props.imgSrc}
                            />
                        </div>
                    )}
                    <div className={styles.right}>
                        <h3 className={styles.header}>{this.props.title}</h3>
                        {this.props.requiredFields && (
                            <div className={styles.requiredFieldsText}>
                                *Required Fields
                            </div>
                        )}
                        <div className={styles.underline} />
                    </div>
                    {this.props.actionItems &&
                        this.props.actionItems.map((actionItem, idx) => (
                            <Fragment key={idx}>
                                {idx > 0 && <span>&nbsp;|&nbsp;</span>}
                                <div
                                    onClick={actionItem.handleSelect}
                                    style={Object.assign(
                                        {},
                                        actionItem.style || {},
                                        {
                                            cursor: 'pointer',
                                        }
                                    )}
                                >
                                    {actionItem.name}
                                </div>
                            </Fragment>
                        ))}
                    {this.props.buttonGroup}
                </div>
            );
        } else if (this.props.icon) {
            return (
                <div
                    className={
                        this.props.requiredFields
                            ? styles['form-section-header-with-required']
                            : styles['form-section-header']
                    }
                >
                    {this.props.icon && (
                        <div className={styles.left}>{this.props.icon}</div>
                    )}
                    <div className={styles.right}>
                        <h3 className={styles.header}>{this.props.title}</h3>
                        {this.props.requiredFields && (
                            <div className={styles.requiredFieldsText}>
                                *Required Fields
                            </div>
                        )}
                        <div className={styles.underline} />
                    </div>
                    {this.props.actionItems &&
                        this.props.actionItems.map((actionItem, idx) => (
                            <Fragment key={idx}>
                                {idx > 0 && <span>&nbsp;|&nbsp;</span>}
                                <div
                                    onClick={actionItem.handleSelect}
                                    style={Object.assign(
                                        {},
                                        actionItem.style || {},
                                        {
                                            cursor: 'pointer',
                                        }
                                    )}
                                >
                                    {actionItem.name}
                                </div>
                            </Fragment>
                        ))}
                    {this.props.buttonGroup}
                </div>
            );
        }
    }
}

export class FormSectionHeaderWithSubTitle extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        subTitle: PropTypes.node,
        actionItems: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                handleSelect: PropTypes.func.isRequired,
                style: PropTypes.object,
            })
        ),
        buttonGroup: PropTypes.element,
    };
    render() {
        return (
            <div className={styles['form-section-header']}>
                <div className={styles['form-section-header-with-subtitle']}>
                    <div className={styles.title}>{this.props.title}</div>
                    <div className={styles.subtitle}>{this.props.subTitle}</div>
                    <div className={styles.underline} />
                </div>
                {this.props.actionItems &&
                    this.props.actionItems.map((actionItem, idx) => (
                        <Fragment key={idx}>
                            {idx > 0 && <span>&nbsp;|&nbsp;</span>}
                            <div
                                onClick={actionItem.handleSelect}
                                style={Object.assign(
                                    {},
                                    actionItem.style || {},
                                    {
                                        cursor: 'pointer',
                                    }
                                )}
                            >
                                {actionItem.name}
                            </div>
                        </Fragment>
                    ))}
                {this.props.buttonGroup}
            </div>
        );
    }
}

export class FormRow extends Component {
    static propTypes = {
        children: PropTypes.node,
        reverse: PropTypes.bool,
        style: PropTypes.object,
    };
    static defaultProps = {};
    render() {
        return (
            <div
                className={
                    this.props.reverse
                        ? styles['form-row-reverse']
                        : styles['form-row']
                }
                style={this.props.style}
            >
                {this.props.children}
            </div>
        );
    }
}

export class FormElement extends Component {
    static propTypes = {
        children: PropTypes.node,
        flex2: PropTypes.bool,
        flex3: PropTypes.bool,
        verticalCenter: PropTypes.bool,
        style: PropTypes.object,
        className: PropTypes.string,
    };

    static defaultProps = {
        flex2: false,
        flex3: false,
        verticalCenter: false,
    };

    render() {
        let className = `${styles['form-element']}${
            this.props.flex2 ? ` ${styles['flex-2']}` : ''
        }${this.props.flex3 ? ` ${styles['flex-3']}` : ''}${
            this.props.verticalCenter ? ` ${styles['vertical-center']}` : ''
        }`;
        if (this.props.className) {
            className += ' ' + this.props.className;
        }
        return (
            <div className={className} style={this.props.style}>
                {this.props.children}
            </div>
        );
    }
}

export class FormElementSeparator extends Component {
    static propTypes = {};
    static defaultProps = {};
    render() {
        return (
            <div className={styles['form-element-separator']}>
                <div />
            </div>
        );
    }
}

export function OneOptionRequiredText() {
    return (
        <div className={styles.requiredText}>
            AT LEAST ONE REQUIRED<span className={styles.requiredStar}>*</span>
        </div>
    );
}
